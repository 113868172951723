import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateVideooptionslist} from '../../../graphql/mutations';
import {Form,Table} from "react-bootstrap";
import {getPlayslide, getVideooptionslist} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/optionshowroom.css";
import DeleteIcon from "../../../img/dash/Delete-Button-X.png";
import Add from "../../../img/dash/Add-Button.png";
import Grid from "@material-ui/core/Grid";

function Oneoptionquesedit() {
    const { id,rid } = useParams();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [uploadedmessage, setUploadedmessage] = useState("");
    const [dname, setDname] = useState('')
    const [polltimer, setPolltimer] = useState(0)
    const [dquestion, setDquestion] = useState('')
    const [items1, setItems1] = useState([{ panswer: '' }])
    const [pchecked, setPchecked] = useState();
    const [showtimerform, setShowtimerform] = useState(false)
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.username)))
            .catch(err => console.log(err));
        (async() => {
            const responses = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = responses.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);
    useEffect( () => {
        (async() => {
            const response = await API.graphql({
                query: getVideooptionslist,
                variables:{id:rid}
            })
            const datas = response.data.getVideooptionslist;
            const dn = datas.vfname
            setDname(dn)
            const tm = datas.vtimer
            if(tm !== 0) {
                setPolltimer(tm)
                setShowtimerform(true)
            }
            const q = datas.vquestion
            setDquestion(q)
            const i1 = datas.vtest
            setItems1(i1)
            const t = Number(datas.vanswer)
            document.getElementsByName('options')[t].checked=true
        })()
    }, []);

    function changedquestion(event){
        setDquestion(event.target.value)
    }
    function addItem1 () {
        setItems1(state => [...state, ''])
    }

    const items1View = items1.map((item, index) => {
        function deleteItem () {
            setItems1(state => {
                return state.filter((item, stateIndex) => index !== stateIndex)
            })
        }
        function changeItem (event) {
            event.persist()
            setItems1(state => {
                return state.map((item, stateIndex) => {
                    const match = index === stateIndex
                    if (match) {
                        const newItem = { ...item, panswer: event.target.value, id: stateIndex }
                        return newItem
                    }
                    return item
                })
            })
        }
        return (
            <li key={index} className="optionslistdistanse">
                <input
                    name="options"
                    value={`${index}`} // make sure to set up defaultValue
                    type="radio"
                    onChange={()=> {
                        setPchecked(`${index}`)
                    }}
                />
                <input value={item.panswer} onChange={changeItem} placeholder=""  className="optionsullwidth"/>
                <img src={DeleteIcon} onClick={deleteItem} className="dragdropdele" />
            </li>
        )
    })

    async function submitItems (event) {
        event.preventDefault();
        const radiobox = document.querySelector('input[name = "options"]:checked');
        if(radiobox != null) {
            try {
                const input = {
                    id: rid,
                    vtimer: polltimer,
                    vquestion: dquestion,
                    vtest: items1,
                    vanswer: pchecked
                }
                await API.graphql({
                    query: updateVideooptionslist,
                    variables: {input}
                });
                setUploadedmessage("Updated!")

            } catch (err) {
                console.log(err)
            }
        }else{
            setUploadedmessage("Please check the correct one.")
        }
    }

    return (
        isOwner === isUser ?
        <div className="orderlist">
            <form onSubmit={submitItems}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Game name: {dname}</Form.Label>
                </Form.Group>
                <Grid container spacing={0}>
                    <Grid item xs={2}>
                        Timer : &nbsp;
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container spacing={0}>
                            <Grid item xs={7} className="cccenter">
                                <input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
                                {showtimerform ?
                                    <input
                                        value={polltimer}
                                        className="dragdroptimer"
                                        type="number"
                                        maxLength="3"
                                        onChange={(e)=>setPolltimer(e.target.value)}
                                    />
                                    : ""
                                }
                            </Grid>
                            <Grid item xs={5} className="cccenter">
                                <input type="radio" name="timer" value="NoTimer" onChange={()=>{
                                    setShowtimerform(false);
                                    setPolltimer(0)
                                }} /> No Timer
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <Form.Group className="mb-3">
                    <Form.Label>Question :</Form.Label>
                    <br/>
                    <textarea type="text" value={dquestion} onChange={changedquestion} className="thiseditque" />
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Answers :</Form.Label>
                    <ol>
                        {items1View}
                    </ol>
                    <div>
                        <img src={Add} onClick={addItem1} />
                    </div>
                </Form.Group>
                <hr/>
                <br/>
                <Table>
                    <tr>
                        <td>
                        </td>
                        <td className="orderright">
                            {uploadedmessage} <input type="submit" value="Update" id="summitb" />
                        </td>
                    </tr>
                </Table>
            </form>
        </div>
            :""
    );
}
export default Oneoptionquesedit;
