import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {createFasteranswer, updatePlayslide} from '../../graphql/mutations';
import { useForm } from "react-hook-form";
import {Form,Table} from "react-bootstrap";
import {getPlayslide} from "../../graphql/queries";
import { v4 as uuid } from 'uuid';
import "./style.css";
import Grid from "@material-ui/core/Grid";
import Resizer from "react-image-file-resizer";
const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            450,
            450,
            "JPEG",
            95,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
function Createflipcards({id, handleClose52}) {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [images, setImages] = useState([]);
    const [selectedFile, setSelectedFile] = useState("");
    const [smessage, setSmessage] = useState("")
    const [opensmessage, setOpensmessage] = useState(true)
    const d = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.username)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, [id]);
    const handleFileChange = async (event) => {
        const img = event.target.files[0];
        const resized = await resizeFile(img);
        setSelectedFile(resized)
    };
    const {register, handleSubmit} = useForm({});
    const reorderurl = `https://v.reachum.com/mediaflipcardshow/${id}/${d}`;
    const erurl = `https://v.reachum.com/mediaflipcardedit/${id}/${d}`;
    const onSubmit = async (data) => {
        try {
            const input = {
                id:d,
                ffname:data.ffname,
                fquestion:data.fquestion,
                fimage:selectedFile,
                fanswer:data.fanswer
            }
            await API.graphql({
                query: createFasteranswer,
                variables: {input}
            });
            const urldata = {
                rid:d,
                title:data.ffname,
                rurl: reorderurl,
                eurl:erurl
            };
            const allImages = [...images, urldata]
            const inputs = {
                id,
                playlistlinks: allImages
            }
            await API.graphql({
                query: updatePlayslide,
                variables: {input:inputs}
            })
            setOpensmessage(false);
            setSmessage("Submitted");
            setTimeout(() => {
                handleClose52()
            }, 1000)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        isOwner === isUser ?
            <>
                <div className="orderlist">
                    <div className="thiscenter">
                        <h3>Flashcard</h3>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={0}>
                            <Grid item xs={3} className="rightside">
                                Card name : &nbsp;
                            </Grid>
                            <Grid item xs={9}>
                                <input
                                    name="ffname"
                                    defaultValue=""
                                    ref={register({ required: true, maxLength:60 })}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={0}>
                            <Grid item xs={3} className="rightside">
                               Front : &nbsp;
                            </Grid>
                            <Grid item xs={9}>
                                <input
                                    name="fanswer"
                                    defaultValue=""
                                    ref={register({ required: true, maxLength: 200})}
                                    className="ques"
                                />
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={0}>
                            <Grid item xs={3} className="rightside">
                                Back : &nbsp;
                            </Grid>
                            <Grid item xs={9}>
				<textarea
                    name="fquestion"
                    defaultValue=""
                    ref={register({ required: true, maxLength: 500})}
                    className="ques"
                />
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={0}>
                            <Grid item xs={3} className="rightside">
                                Image : &nbsp;
                            </Grid>
                            <Grid item xs={9}>
                                <input type="file" onChange={handleFileChange} />
                            </Grid>
                        </Grid>
                        <br/>
                        <div className="cccenter">
                        <img src={selectedFile} />
                        </div>
                        <br/>
                        <Table>
                            <tbody>
                            <tr><td>
                            </td>
                                <td className="orderright">
                                    {opensmessage ?
                                        <input type="submit" className="answersubmit" value="Submit"/>
                                        :
                                        <p className="redthis"> {smessage} </p>
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </form>
                </div>
            </>
            :""
    );
}
export default Createflipcards;
