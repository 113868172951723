import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {API, Auth} from "aws-amplify";
import Foot from "../components/Foot";
import {Form, Row, Col} from 'react-bootstrap';
import Navbars from "../components/Navbar";
import "./css/profile.css";
import Login from "./login";
import {listHosts} from "../graphql/queries";
import Manageaccount from "./manageaccount"
import {Helmet} from "react-helmet";

const Profile = ()  => {
    const history = useHistory();
    const [user, setUser] = useState();
    const [isEmail, setIsemail] = useState();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
                const username = user.username;
                setIsemail(user.attributes.email);
                API.graphql({
                    query: listHosts,
                    variables: {
                        filter: {
                            "owner": {
                                eq: username
                            }
                        },
                        limit:10000
                    }
                }).then(datas => {
                    const players = datas.data.listHosts.items;
                    const sids = players[0].status;
                    if(sids) {
                        sessionStorage.setItem("amysstatus", sids)
                        const options = {
                            method: 'GET',
                            headers: {
                                accept: 'application/json',
                                ApiKey: 'click-video_6598e22a7257474591cab3ad5b4211ff'
                            }
                        };
                        fetch(`https://public.billsby.com/api/v1/rest/core/reachum/subscriptions/${sids}`, options)
                            .then(response => response.json())
                            .then(response => {
                                    const thisplan = response.customerUniqueId;
                                    sessionStorage.setItem("amycid", thisplan)
                                }
                            )
                            .catch(err => console.error(err));
                    }
                })
            })
            .catch((err) => console.log(err));
    }, []);

    const handleSignout = async () => {
        try {
            await Auth.signOut();
            history.replace("/");
            window.location.reload();
        } catch (err) {
            console.log('error signing out: ', err);
        }
    }
    async function deleteUser() {
        let text = "Are you sure you want to delete this account? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                await Auth.deleteUser();
                history.replace("/");
                window.location.reload();
            } catch (error) {
                console.log('Error deleting user', error);
            }
        }
    }
    return(
        <>
            <Helmet>
                <title>MY ACCOUNT</title>
            </Helmet>
            <Navbars />
            {!user ?
                <Login />
                :(
                <div className="profiles">
                    <Form>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="4">
                                Email
                            </Form.Label>
                            <Col sm="8">
                                <p>{isEmail}</p> <p user={user}  onClick={handleSignout} className="underline"> Sign Out </p>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlai">
                            <Form.Label column sm="5">
                            </Form.Label>
                            <Col sm="7">
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintext">
                            <Form.Label column sm="4">
                                Manage account
                            </Form.Label>
                            <Col sm="8">
                                <Manageaccount />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlai">
                            <Form.Label column sm="5">
                            </Form.Label>
                            <Col sm="7">
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextPassword">
                            <Form.Label column sm="4">
                            </Form.Label>
                            <Col sm="8">
                                <p user={user} className="underlinetext" onClick={deleteUser}>Delete Account</p>
                            </Col>
                        </Form.Group>
                    </Form>
                    </div>
                )
            }
                    <Foot />
                </>

    )
}


export default Profile;
