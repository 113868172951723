import React, { useEffect, useState } from "react";
import Navbars from "../components/Navbar";
import Login from "../pages/login";
import Foot from "../components/Foot";
import {Auth} from "aws-amplify";
import { Button} from 'react-bootstrap';
import "./css/pricing.css";
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";

const Pricing = () => {
    const [user, setUser] = useState();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
                const reachumemail = user.attributes.email;
                sessionStorage.setItem("vreachumemail",reachumemail);
            })
    }, []);

    function gowebsite(){
        window.open("https://reachum.com/contact-us/", "_blank", "noreferrer");
    }
    const linkHtml = `<a href="javascript:void(0)" data-billsby-type="checkout" data-billsby-product="22408" data-billsby-plan="35213" class="pricingbutton">Subscribe</a>`;
    const linkHtml1 = `<a href="javascript:void(0)" data-billsby-type="checkout" data-billsby-product="22408" data-billsby-plan="35214" class="pricingbutton">Subscribe</a>`;
    return (
        <>
            <Helmet>
                <title>PRICING</title>
            </Helmet>
            <Navbars/>
            {!user ?
                <Login />
                : (
                    <>
                        <div className="homepage">
                            <div className="centerside">
                                <br/>
                                <h2 className="redthisall">All inclusive pricing, for any situation.</h2>
                                <br/>
                                <p>Acquire the power to create, convince, teach, onboard, sell, and collect money in one live experience platform, without limits on time or usage.</p>
                            </div>
                            <br/>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Card  className="text-center">
                                        <p className="topheader">MOST POPULAR</p>
                                        <div className="cardback">
                                            <h3>In-Person</h3>
                                            <p>High value learning experiences</p>
                                        </div>
                                        <Card.Body>
                                            <h1 className="redthisall">$12</h1>
                                            <p>Monthly | $125/Year</p>
                                            <br/>
                                            <br/>
                                            <ListGroup variant="flush" className="leftside">
                                                <ListGroup.Item>Live learning platform</ListGroup.Item>
                                                <ListGroup.Item>Gamification</ListGroup.Item>
                                                <ListGroup.Item>Connect to any online device</ListGroup.Item>
                                                <ListGroup.Item>Displaces social media and notifications</ListGroup.Item>
                                                <ListGroup.Item>Low friction for teachers and students</ListGroup.Item>
                                                <ListGroup.Item>Share Powerpoint, Photos, Webpages, PDFs, Gifs</ListGroup.Item>
                                                <ListGroup.Item>Polls and Quizzes with Shared Results</ListGroup.Item>
                                                <ListGroup.Item>Games & Assessments</ListGroup.Item>
                                                <ListGroup.Item>Simulations</ListGroup.Item>
                                                <ListGroup.Item>Content Manager</ListGroup.Item>
                                                <ListGroup.Item>2 second connection via QR Scan or URL Click</ListGroup.Item>
                                                <ListGroup.Item>Leaderboards</ListGroup.Item>
                                                <ListGroup.Item>Up to 200 participants per session</ListGroup.Item>
                                                <ListGroup.Item>Repeatable lessons</ListGroup.Item>
                                                <ListGroup.Item>Reusable content</ListGroup.Item>
                                                <ListGroup.Item>Giphy animated gif library</ListGroup.Item>
                                                <ListGroup.Item>Flawless video sharing with host control</ListGroup.Item>
                                                <ListGroup.Item>No installation required. Works in browsers.</ListGroup.Item>
                                                <ListGroup.Item>Text Editor</ListGroup.Item>
                                                <ListGroup.Item>Attention tracking</ListGroup.Item>
                                                <ListGroup.Item>3 hour limit per session</ListGroup.Item>
                                                <ListGroup.Item>Realtime reporting</ListGroup.Item>
                                            </ListGroup>
                                            <hr/>
                                            <br/>
                                            <div dangerouslySetInnerHTML={{ __html: linkHtml }} />
                                            <i className="icode">If the button is unresponsive, please reload the page and click it again.</i>
                                            <br/>
                                            <br/>
                                            <Card.Text>
                                                Email support with 48 hour response time.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card  className="text-center">
                                        <p className="topheader">COST EFFECTIVE</p>
                                        <div className="cardback">
                                            <h3>Hybrid</h3>
                                            <p>All In-Person features, plus</p>
                                        </div>
                                        <Card.Body>
                                            <h1 className="redthisall">$24</h1>
                                            <p>Monthly | $250/Year</p>
                                            <br/>
                                            <br/>
                                            <ListGroup variant="flush" className="leftside">
                                                <ListGroup.Item>Up to 200 live participants</ListGroup.Item>
                                                <ListGroup.Item>24 concurrent videos visible</ListGroup.Item>
                                                <ListGroup.Item>Breakout Rooms</ListGroup.Item>
                                                <ListGroup.Item>Screensharing</ListGroup.Item>
                                                <ListGroup.Item>Video recording</ListGroup.Item>
                                                <ListGroup.Item>Chat</ListGroup.Item>
                                                <ListGroup.Item>Timer</ListGroup.Item>
                                                <ListGroup.Item>Manage people</ListGroup.Item>
                                                <ListGroup.Item>Waiting Room</ListGroup.Item>
                                                <ListGroup.Item>Access Contro</ListGroup.Item>
                                                <ListGroup.Item>Background Effects</ListGroup.Item>
                                                <ListGroup.Item>Multiple hosts</ListGroup.Item>
                                                <ListGroup.Item>Instant layout changes</ListGroup.Item>
                                                <ListGroup.Item>Secure transactions</ListGroup.Item>
                                            </ListGroup>
                                            <hr/>
                                            <br/>
                                            <div dangerouslySetInnerHTML={{ __html: linkHtml1 }} />
                                            <i className="icode">If the button is unresponsive, please reload the page and click it again.</i>
                                            <br/>
                                            <br/>
                                            <Card.Text>
                                                Email support with 24 hour response time.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card  className="text-center">
                                        <p className="topheader">CUSTOMIZED</p>
                                        <div className="cardback">
                                            <h3>Enterprise</h3>
                                            <p>Custom package pricing for 10+ users</p>
                                        </div>
                                        <Card.Body>
                                            <h1 className="redthisall">Package Pricing</h1>
                                            <p>for 10+ users</p>
                                            <br/>
                                            <br/>
                                            <ListGroup variant="flush" className="leftside">
                                                <ListGroup.Item>Custom subdomain (you.reachum.com)</ListGroup.Item>
                                                <ListGroup.Item>Custom Branding</ListGroup.Item>
                                                <ListGroup.Item>Integration with LMS</ListGroup.Item>
                                                <ListGroup.Item>Student Tracking</ListGroup.Item>
                                                <ListGroup.Item>SSO via Google, Microsoft 360, Facebook, LMS</ListGroup.Item>
                                                <ListGroup.Item>Share lesson</ListGroup.Item>
                                                <ListGroup.Item>User Management</ListGroup.Item>
                                                <ListGroup.Item>CRM Integration</ListGroup.Item>
                                                <ListGroup.Item>CMS Integration</ListGroup.Item>
                                                <ListGroup.Item>Scaling to 10,000 with 2 hour notice</ListGroup.Item>
                                                <ListGroup.Item>Transcription</ListGroup.Item>
                                                <ListGroup.Item>Shopify integration</ListGroup.Item>
                                                <ListGroup.Item>Canva integraton</ListGroup.Item>
                                                <ListGroup.Item>Role Playing</ListGroup.Item>
                                                <ListGroup.Item>Performance Analysis</ListGroup.Item>
                                                <ListGroup.Item>Chat support responds in 2 hours</ListGroup.Item>
                                                <ListGroup.Item>Miro whiteboard integration</ListGroup.Item>
                                                <ListGroup.Item>Agreement signing</ListGroup.Item>
                                            </ListGroup>
                                            <hr/>
                                            <br/>
                                            <Button variant="success" on onClick={gowebsite}>Contact us</Button>
                                            <br/>
                                            <br/>
                                            <Card.Text>
                                                Online support with 4 hour response time.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>

                    </>
                )
            }
            <Foot/>
        </>
    );
}
export default Pricing;
