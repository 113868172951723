import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import { updateFasteranswer} from '../../../graphql/mutations';
import { useForm } from "react-hook-form";
import {Form,Table} from "react-bootstrap";
import {getFasteranswer, getPlayslide} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/fastaneditpage.css";
import Grid from "@material-ui/core/Grid";
import Resizer from "react-image-file-resizer";
const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			400,
			400,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
function Fastansweredit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [fname, setFname] = useState("");
	const [ftimers, setFtimers] = useState(0);
	const [fques, setFques] = useState("");
	const [fansw, setFansw] = useState("");
	const [thisimg, setThisimg] =useState("");
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [showtimerform, setShowtimerform] = useState(false)
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.username)))
			.catch(err => console.log(err));
		(async() => {
			const responses = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = responses.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const responses = await API.graphql({
				query: getFasteranswer,
				variables:{id:rid}
			})
			const datas = responses.data.getFasteranswer;
			const ffname = datas.ffname;
			setFname(ffname)
			const ftimer = datas.ftimer;
			if(ftimer !== 0) {
				setFtimers(ftimer);
				setShowtimerform(true)
			}
			const fquestion = datas.fquestion;
			setFques(fquestion)
			const fanswer = datas.fanswer;
			setFansw(fanswer);
			const thisimg = datas.fimage;
			if(thisimg){
				setThisimg(thisimg);
			}
		})()
	}, [rid]);
	const {register, handleSubmit} = useForm({});
	const handleFileChange = async (event) => {
		const img = event.target.files[0];
		const resized = await resizeFile(img);
		setThisimg(resized)
	};
	const onSubmit = async (data) => {
		try {
			const input = {
				id:rid,
				ftimer:ftimers,
				fquestion:data.fquestion,
				fanswer:data.fanswer,
				fimage:thisimg
			}
			await API.graphql({
				query: updateFasteranswer,
				variables: {input}
			});
			setUploadedmessage(" Updated. ")
		} catch (err) {
			console.log(err)
		}
	}
	return (
		isOwner === isUser ?
		<>
			<div className="fastaneditpage">
		<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={0}>
					<Grid item xs={3} className="rightside">
						Game name: &nbsp;
					</Grid>
					<Grid item xs={9}>
						{fname}
					</Grid>
				</Grid>
			<br/>
			<br/>
				<Grid container spacing={0}>
					<Grid item xs={3} className="rightside">
						Timer : &nbsp;
					</Grid>
					<Grid item xs={9}>
						<Grid container spacing={0}>
							<Grid item xs={7} className="cccenter">
								<input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
								{showtimerform ?
									<input
										name="ftimer"
										value={ftimers}
										className="timers"
										type="number"
										maxLength="3"
										onChange={(e)=>setFtimers(e.target.value)}
									/>
									: ""
								}
							</Grid>
							<Grid item xs={5} className="cccenter">
								<input type="radio" name="timer" value="NoTimer" onChange={()=>{
									setShowtimerform(false);
									setFtimers(0)
								}} /> No Timer
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			<br/>
				<Grid container spacing={0}>
					<Grid item xs={3} className="rightside">
						Question : &nbsp;
					</Grid>
					<Grid item xs={9}>
				<textarea
					name="fquestion"
					defaultValue={fques}
					ref={register({ required: true, maxLength: 200})}
					className="ques"
				/>
					</Grid>
				</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3} className="rightside">
					Image : &nbsp;
				</Grid>
				<Grid item xs={9}>
					<input type="file" onChange={handleFileChange} />
				</Grid>
			</Grid>
		<br/>
			<div className="mathcccenter">
				<img src={thisimg} className="pximages400"/>
			</div>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3} className="rightside">
				Correct answer : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<input
					name="fanswer"
					defaultValue={fansw}
					ref={register({ required: true, maxLength: 200})}
					className="fullwidth"
				/>
				</Grid>
			</Grid>
			<br/>
			<br/>
			<Table>
				<tr>
					<td>
					</td>
				<td className="orderright">
					{uploadedmessage} <input type="submit" value="Update" className="answersubmit" />
				</td>
				</tr>
			</Table>
		</form>
			</div>
			</>:""
	);
}
export default Fastansweredit;
