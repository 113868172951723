import React from "react";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import "./css/login.css";

const Loginmobile = () => {
    return (
        <>
            <br/>
            <br/>
            <br/>
            <Authenticator loginMechanisms={['email']} hideSignUp={true}>
            </Authenticator>
            <div className="needaaccount">
                    <p className="copytodesktop">Need an account? <br/>Open <span className="undertodesktop">v.reachum.com</span> on your desktop.</p>
            </div>
        </>
    )
}

export default Loginmobile
