import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {createOptionsdrawlist, updatePlayslide} from '../../../graphql/mutations';
import { useForm, useFieldArray } from "react-hook-form";
import {Form,Table} from "react-bootstrap";
import {getPlayslide} from "../../../graphql/queries";
import { v4 as uuid } from 'uuid';
import "../../css/poll.css";
import DeleteIcon from "../../../img/dash/Delete-Button-X.png";
import Add from "../../../img/dash/Add-Button.png";
import  Draws  from "./draw";
import Grid from "@material-ui/core/Grid";


function Drawoptions({id, handleClose54}) {
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [pchecked, setPchecked] = useState();
	const [imgdata, setImgdata] = useState("");
	const [ftimers, setFtimers] = useState(0);
	const [showtimerform, setShowtimerform] = useState(false);
	const [smessage, setSmessage] = useState("")
	const [opensmessage, setOpensmessage] = useState(true)
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.username)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, []);

	const {register, control, handleSubmit} = useForm({
		defaultValues: {
			ptest: [{panswer: ""}]
		}
	});

	const {fields, append, remove} = useFieldArray(
		{
			control,
			name: "ptest"
		}
	);
	const reorderurl = `https://v.reachum.com/drawoptionshowroom/${id}/${d}`;
	const erurl = `https://v.reachum.com/drawoptionsedit/${id}/${d}`;
	const ccurl = `https://v.reachum.com/drawcorrctanswer/${id}/${d}`;
	const ileaderboardurl = `https://v.reachum.com/leaderboard/${id}`;
	const tt = `${d}1`;
	const ccu = `${d}333`;
	const handleChildData = (data) => {
		setImgdata(data)
	};
	const onSubmit = async (data) => {
		if(pchecked) {
			try {
				const input = {
					id: d,
					pfname: data.pfname,
					ptimer: ftimers,
					pquestion: data.pquestion,
					ptest: data.ptest,
					drawques:imgdata,
					oanswer: pchecked
				}
				await API.graphql({
					query: createOptionsdrawlist,
					variables: {input}
				});
				const urldata = {
					rid: d,
					title: data.pfname,
					rurl: reorderurl,
					eurl:erurl
				};
				const leaderboard = {
					rid:tt,
					title:"leaderboard",
					rurl:ileaderboardurl
				}
				const correcta = {
					rid:ccu,
					title:"correct answer",
					rurl: ccurl
				}
				const allImages = [...images, urldata,correcta,leaderboard]
				const inputs = {
					id,
					playlistlinks: allImages
				}
				await API.graphql({
					query: updatePlayslide,
					variables: {input: inputs}
				})
				setOpensmessage(false);
				setSmessage("Submitted");
				setTimeout(() => {
					handleClose54()
				}, 1000)
			} catch (err) {
				console.log(err)
			}
		}else{
			const thism = "Please check the correct one. "
			setUploadedmessage(thism)
		}
	}

	return (
		isOwner === isUser ?
		<>
			<div className="orderlist">
				<div className="thiscenter">
					<h3>Draw quiz and choose one answer option</h3>
				</div>
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={3}>
			Game Name: &nbsp;
			</Grid>
				<Grid item xs={9}>
				<input
					name="pfname"
					defaultValue=""
					ref={register({ required: true, maxLength:60 })}
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Timer : &nbsp;
				</Grid>
				<Grid item xs={9}>
					<Grid container spacing={0}>
						<Grid item xs={7} className="cccenter">
							<div className="labelinlinetimer">
								<label>
									<input
										type="radio"
										name="timer"
										value="AddTimer"
										onChange={()=>{setShowtimerform(true)}}
										className="biggerradiotimer"
									/>
									Add Timer (Seconds) &nbsp;
								</label>
							{showtimerform ?
								<input
									value={ftimers}
									className="timers"
									type="number"
									maxLength="3"
									onChange={(e)=>setFtimers(e.target.value)}
								/>
								: ""
							}
							</div>
						</Grid>
						<Grid item xs={5} className="cccenter">
							<label>
							<input type="radio" name="timer" value="NoTimer" onChange={()=>{
								setShowtimerform(false);
								setFtimers(0)
							}} className="biggerradiotimer" /> No Timer
							</label>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
			Question: &nbsp;
				</Grid>
				<Grid item xs={9}>
				<textarea
					name="pquestion"
					defaultValue=""
					ref={register({ required: true, maxLength:200 })}
					className="ques"
				/>
				</Grid>
			</Grid>
			<br/>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<div className="centerthis">
				<span className="redthis">After clicking the submit button, the whiteboard cannot be changed.</span>
				<Draws onChildData={handleChildData} />
				</div>
			</Form.Group>
			<Form.Group className="mb-3" controlId="formBasicPassword">
				<Form.Label>Answers and check the correct one</Form.Label>
			<ol>
				{fields.map((item, index) => {
					return (
						<>
							<Table borderless>
								<tr>
						<li key={item.id}>
							<td>
								<input
									name={`ptest[${index}].id`}
									defaultValue={`${index}`} // make sure to set up defaultValue
									ref={register()}
									type="hidden"
								/>
								<input
									name="options"
									defaultValue={`${index}`} // make sure to set up defaultValue
									ref={register()}
									type="radio"
									onChange={()=> {
										setPchecked(`${index}`)
									}}
									className="singleradio"
								/>
							<input
								name={`ptest[${index}].panswer`}
								defaultValue={`${item.panswer}`} // make sure to set up defaultValue
								ref={register({maxLength:100})}
								className="orderlistinput"
							/>
							</td>
							<td className="polldelete">
								<img src={DeleteIcon} onClick={() => remove(index)} width="30" height="30" />
							</td>
						</li>
								</tr>
							</Table>
						</>
					);
				})}
			</ol>
				<div>
				<img src={Add} id="polladdsb" onClick={() => {
					append({ panswer: "" });
				}} />
				</div>
			</Form.Group>
			<hr/>
			<br/>
			<Table>
				<tr>
					<td>
					</td>
				<td className="orderright">
					{opensmessage ?
						<>
							{uploadedmessage} <input type="submit" value="Submit" id="summitb" />
						</>
						:
						<p className="redthis"> {smessage} </p>
					}
				</td>
				</tr>
			</Table>
		</form>
			</div>
			</>
			:""
	);
}
export default Drawoptions;
