import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import { getAdobeexpress } from "../../graphql/queries";
import {useParams} from "react-router-dom";
import "../css/adobe.css"

function  Adobeshow(){
    const { id,rid } = useParams();
    const [itt, setItt] = useState("");
    const [images, setImages] = useState([]);
    useEffect( () => {
        (async() => {
            const response = await API.graphql({
                query: getAdobeexpress,
                variables:{id:rid}
            })
            const datas = response.data.getAdobeexpress;
            const images = datas.adobeimags;
            if(images != null) {
                setImages(images);
            }
            const tt = datas.dname;
            setItt(tt)
        })()
    }, []);
    return (
                        <>
                        <div className="centerside">
                            {images.map((item, index) => (
                                <>
                                    <br/>
                                    <br/>
                                    <div key={index}><img src={item.data} className="adobedisplay" alt={itt}/></div>
                                    <br/>
                                    <br/>
                                </>
                            ))}
                        </div>
        </>
    )
}


export default Adobeshow;

