import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import GiphyGrid from './giphyGrid';
import SearchInput from './SearchInput';
import GifModal from './gifModal';
import {API, Auth} from "aws-amplify";
import {getPlayslide} from "../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'normal',
    overflow: 'scroll',
  },
  content: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: "100%",
    height: "100vh",
      backgroundColor:"#ffffff"
  },
}));

const Giphy = ({id, handleClose53})  => {
    const classes = useStyles();
    const [modalGif, setModalGif] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.username)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);
    const doSearch = (action) => {
      setSearchTerm(action);
    }

    const clearSearch = () => {
      setSearchTerm('');
    }

    const closeModal = () => {
      setModalGif(null);
        handleClose53()
    }

    return(
        isOwner === isUser ?
            <>
      <div className={classes.root}>
        <div className={classes.content}>

          <SearchInput onSearch={doSearch} onClear={clearSearch} />

          <GiphyGrid
            searchTerm={searchTerm}
            onGifClick={(gif, e) => {
              e.preventDefault();
              setModalGif(gif);
            }}
          />
          {modalGif && <GifModal gif={modalGif} onClose={closeModal}/> }
        </div>
      </div>
            </>
            :""
    )
}

export default Giphy;
