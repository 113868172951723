import {Auth, API, Hub} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {listPlayslides,listHosts} from "../graphql/queries";
import {useHistory} from "react-router-dom";
import {newUpdatePlayslide,newDeletePlayslide} from "../graphql/subscriptions";
import "./css/dashboard.css";
import Navbarmobile from "../components/Navbar-mobile";
import Foot from "../components/Foot";
import Foott from "../components/Foott";
import Loginmobile from "./login-mobile";
import {Helmet} from "react-helmet";
import {Button, Modal} from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import "react-datepicker/dist/react-datepicker.css";
import Grid from "@material-ui/core/Grid";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {v4 as uuid} from "uuid";
import {createHost} from "../graphql/mutations";

function Dashboardmobile() {
    const [myData, setMyData] = useState([])
    const [user, setUser] = useState();
    const [myplan, setMyplan] = useState("");
    const [username, setUsername] = useState();
    const [copied, setCopied] = useState("");
    const [thisid,setThisid] = useState("");
    const [thismid,setThismid] = useState("");
    const [isthisplan, setIsthisplan] = useState(true);
    const [thisname,setThisname] = useState("")
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    let history = useHistory();
    const d = uuid();
    useEffect(() => {
        getUserData();
        Hub.listen('auth', (data) => {
            const { payload } = data;
            onAuthEvent(payload);
        })
        fetchData();
        const deletePlayslide = API.graphql(
            {
                query:newDeletePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
                window.location.reload();
            }
        })
        const updatePlayslide = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        return()=>{
            deletePlayslide.unsubscribe();
            updatePlayslide.unsubscribe()
        }
    }, []);
    const  getUserData = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const usere = user.attributes.email;
        if(user){
            setUsername(user.username);
            setUser(user);
            const username = user.username;
            const useremail = user.attributes.email;
            sessionStorage.setItem("useremail", username)
            try {
                API.graphql({
                    query: listHosts,
                    variables: {
                        filter: {
                            "owner": {
                                eq: username
                            }
                        },
                        limit:10000
                    }
                }).then(datas => {
                    const players = datas.data.listHosts.items;
                        const sids = players[0].status;
                        if(sids !== null) {
                            const options = {
                                method: 'GET',
                                headers: {
                                    accept: 'application/json',
                                    ApiKey: 'click-video_6598e22a7257474591cab3ad5b4211ff'
                                }
                            };
                            fetch(`https://public.billsby.com/api/v1/rest/core/reachum/subscriptions/${sids}`, options)
                                .then(response => response.json())
                                .then(response => {
                                        const rs = response.status;
                                        const thisplan = response.planId;
                                        if (rs === "Active") {
                                            if (thisplan === 35213) {
                                                setMyplan("You plan is In-person Experiences");
                                                setIsthisplan(false);
                                            } else {
                                                setMyplan("You plan is Hybrid Experiences")
                                            }
                                        } else {
                                            history.replace("/pricing");
                                        }
                                        if (rs === "Cancelled") {
                                            history.replace("/pricing");
                                        }
                                    }
                                )
                                .catch(err => console.error(err));
                        }else{
                            const timestamp2 = Math.floor(new Date().getTime() / 1000);
                            const tt = players[0].capacity;
                            const differenceInTime = tt - timestamp2;
                            const differenceInDays = Math.round(differenceInTime / (3600 * 24));
                            const ttdays = `${differenceInDays} days free trial`;
                            if (differenceInDays === 0 || differenceInDays < 0) {
                                const query = new URLSearchParams(window.location.search);
                                const sid = query.get("sid");
                                if(!sid){
                                    history.replace("/pricing");
                                }
                            } else {
                                setMyplan(ttdays)
                            }
                        }
                })
            }catch (error){
                console.log(error)
            }
        }else{
            setUser(null)
        }
        window.$productFruits.push(['init', 'COTdf6SRBMA6khBF', 'en', { username: usere, email:usere}]);
        window.heap.identify(usere);
    }
        function onAuthEvent(payload)  {
        switch (payload.event) {
            case "signIn":
                getUserData();
                break;
            case "signUp":
                break;
            default:
                return
        }
    }
    async function fetchData() {
        const response = await API.graphql({
            query: listPlayslides
        });
        const datas = response.data.listPlayslides.items;
        const scending = [...datas].sort((a, b) => a.meetingname.toLowerCase() < b.meetingname.toLowerCase() ? -1 : 1);
        setMyData(scending);
    }
    const renderData = () => {
        return myData.map((item,index) => (
            item.owner === username ?
                <ListGroup.Item eventKey={index} key={index} className="d-flex justify-content-between align-items-start" action variant="light">
                                <Grid container spacing={0}>
                                    <Grid item xs={8}>
                                <span> {item.meetingname}</span>
                                    </Grid>
                                    <Grid item xs={4} className="rightside">
                                <button onClick={() => {
                                    setShow(true);
                                    setThisname(item.meetingname);
                                    setThisid(item.id);
                                    setThismid(item.mid)
                                }} className="golivebutton">Meeting Links</button>
                                    </Grid>
                                </Grid>
                </ListGroup.Item>
                :""
            ))
    }
    const renderDatalinks = () => {

        return (
<>
                                        <button onClick={() =>{
                                            history.push(`/mobilehost/${thisid}/${thismid}`);
                                        }} className="startbuttoncolor">Start Meeting</button>
                                    <br/>
                                    <br/>
                                    <span className="darkgreen">GUEST LINK (Click to Copy):</span>
                            <br/>
                            <CopyToClipboard text={`https://v.reachum.com/view/${thisid}`} onCopy={() => {
                                setCopied(true);
                                setTimeout(() => {
                                    setCopied(false);
                                }, 3000);
                            }}>
                                    <span className="buttoncolor">{`https://v.reachum.com/view/${thisid}`}</span>
                            </CopyToClipboard>
                            <br/>
                            {copied ? <span style={{color: 'red'}}>Copied</span> : null}
                            <br/>
                            </>
        )
    }
    const handleSignout = async () => {
        try {
            await Auth.signOut();
            history.replace("/m");
            window.location.reload();
        } catch (err) {
            console.log('error signing out: ', err);
        }
    }
    return (
                        <>
                            <Helmet>
                                <title>REACHUM | DASHBOARD</title>
                            </Helmet>
                            <Navbarmobile />
                            {!user ?
                                <Loginmobile />
                                :(
                                <>
                                                <div className="mobilehomepage">
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={9}>
                                                            <h2> Room List </h2>
                                                        </Grid>
                                                        <Grid item xs={3} className="rightside">
                                                            <Button user={user} variant="warning" size="sm" onClick={handleSignout}>Sign Out</Button>
                                                        </Grid>
                                                    </Grid>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={12} className="rightside">
                                                                    <p className="leftview">{myplan}</p>
                                                            </Grid>
                                                        </Grid>

    <ListGroup as="ol" numbered>
                                                        {renderData()}
        </ListGroup>

                                                </div>
                                    <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                                        <Modal.Header closeButton>
                                            {thisname} - Meeting Links
                                        </Modal.Header>
                                        <Modal.Body>
                                            {isthisplan ?
                                                <>
                                                    {renderDatalinks()}
                                                </>
                                                :
                                                <p>Hybrid Experience Plan only</p>
                                            }
                                        </Modal.Body>
                                    </Modal>
                                    </>
                                )
                                    }
                            <Foot/>
                            <Foott />
                                </>

    );
}
export default Dashboardmobile;

