import React, {useEffect, useState} from 'react'
import {API, Auth} from "aws-amplify";
import {getSequencinggame} from "../../../graphql/queries";
import {useParams} from "react-router-dom";

export default function Correctsequencing () {
  const { id, rid } = useParams();
  const [dquestion, setDquestion] = useState('')
  const [categoris, setCategoris] = useState([])
  useEffect( () => {
    (async() => {
      const response = await API.graphql({
        query: getSequencinggame,
        variables:{id:rid}
      })
      const datas = response.data.getSequencinggame;
      const q = datas.dquestion
      setDquestion(q)
      const t1 = datas.orders;
      setCategoris(t1)
    })()
  }, []);
  const renderData = () => {
    return (
        <div>
          {categoris.map((item, index) => (
              <li key={index} className="selist">
                {item.text} &nbsp; &nbsp;
                <img src={item.image} width="300px"/>
              </li>
          ))}
        </div>
    );
  };

  return (
      <div className="correctanswerpage">
      <div className="corrctcentermath">
        <h2>{dquestion}</h2>
        <p>The correct answer is: </p>
      <ol className="centerthisse">
        {renderData()}
      </ol>
        <br/>
        <br/>
        <br/>
    </div>
     </div>
  )
}
