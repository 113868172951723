import {API} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide} from "../../graphql/queries";
import { updatePlayslide} from "../../graphql/mutations";
import {useHistory,useParams} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import "../css/preview.css";
import Right from "../../img/host/next-btn.png";
import Left from "../../img/host/back-btn.png";
import {Helmet} from "react-helmet";
import Ending from "../../img/host/Exit-Button.png";
import Modal from "react-bootstrap/Modal";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Replaybutton from "./replay.png";


function EmojiPicker({ onSelect }) {
    const emojis = ["(ง︡'-'︠)ง", "≧◉ᴥ◉≦", "ʕ•́ᴥ•̀ʔっ", "✍(◔◡◔)", "(っ＾▿＾)💨", "(❛‿❛✿̶̥̥)","(^◡^ )", "(ɔ◔‿◔)ɔ ♥", "(>‿◠)✌", "( ͡❛ ͜ʖ ͡❛)",
        "(.•̃ ᴗ•̃.)","(╥﹏╥)","(ᵔ̃ ⏥ᵔ̃)","( ◡́.◡̀)",
        "(ᵔ̃ ‿‿ᵔ̃)","(ᵔ̃ ‿ᵔ̃)","( ͠👁️ ͜ʖ ͡👁️)","( ͠ᵔ ͜ʖ ͡ᵔ)","(ᵔ̃ ͜ʖᵔ̃)","（ つ︣﹏╰）",
        "( ͡❛ ‿‿ ͡❛)","( ͡° ‿‿ ͡°)","( ͡ᵔ ‿‿ ͡ᵔ)",
        "(.•̃ ⏥•̃.)","(͡° ͜ʖ ͡°)","(.👁️̃ ⏥👁️̃.)","(◑_◑)","¯\\_( ͠👁️ ͜ʖ ͡👁️)_/¯","(̶◉͛‿◉̶)","(.•̃ ͜ʖ•̃.)","¯\\_( ͠~ ͜ʖ ͡°)_/¯","(.°̃ ͜ʖ°̃.)","(. •︠ ͜ʖ ︡•.)",
        "(. •︠ ⏥ ︡•.)","( ͡ᵔ ‿‿͡ᵔ )","(ᵔ̃ ‿‿ᵔ̃)","(👁️̃ ‿‿👁️̃)","ಥ_ಥ","ᕙ(`▿´)ᕗ",
        "¯\\_(•̃ ⏥•̃)_/¯","( •︣ ▿ •︣ )","( ͡👁️ ‿‿ ͡👁️)","(ㆆ_ㆆ)","(👁️̃ ‿‿👁️̃)","( ͠ᵔ ‿‿ ͡ᵔ)✌","( ͠ᵔ ͜ʖ ͡ᵔ)✌","( ˘︹˘ )","t(>.<t)","(≖_≖ )","(͠◉_◉᷅ )","(◑́_◑᷅ )", "( ͡• ▿ ͡•)"];
    return (
        <div>
            {emojis.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker1({ onSelect }) {
    const emojis1 = ["🥪", "🥙", "🥯", "🍖", "☕", "🥛", "🥚", "🍠", "🍥", "🍓",
        "🍳","🍱", "🍤","🥤","🧋", "🍷","🍹","🍸","🍻","🍺","🍦",
        "🍨","🍧","🎂","🍰","🍯","🍫","🍬","🍭","🍡","🍩","🍪", "🍘","🍮","🍌","🍉","🍊","🍍","🍎","🍏","🍑","🍋",
        "🍈","🍐","🍒","🍇","🍅","🍆","🌽","🍣","🍚","🍛","🍙","🍲","🍜","🍝","🍢","🍞","🍗","🍟","🍔","🍕","🍄"];
    return (
        <div>
            {emojis1.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker2({ onSelect }) {
    const emojis2 = ["🚚", "🚔", "🚑", "🚓", "🚒", "🚕", "🚖", "🚛",
        "🚐","🚗", "🚘","🚙","🚜", "🚉","🚊","🚇","🚥","🚆","🚞","🚲","🏍","🦽","🦼","🛺","🛵","🛻","🏎","🚍","⛽",
        "🚄","🚅","🚝","🚈","🚠","🚡","🚎","🚃","🚋","🚌","✈", "🚁","⚓","🚣","⛵","🏄","🏊","🚀","🛴"];
    return (
        <div>
            {emojis2.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker3({ onSelect }) {
    const emojis3 = ["⚽", "(づ˶•༝•˶)づ  🏀", "🤺","⚾","🥎","🏐","🏈","🏉","🎾","🥏","🎳","🏏","🏑","🏒","🥍","🏓","🏸","🥊","🥋",
        "🥅","⛳","⛸️","🎣","🤿","🎽","🎿","🛷","🥌"];
    return (
        <div>
            {emojis3.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function SelfStudy() {
    const history = useHistory();
    const [urls, setUrls] = useState([]);
    const [count, setCount] = useState(0);
    const [isOpen, setIsOpen] = useState(true);
    const [yourusername, setYourusername] = useState('');
    const [openemojis, setOpenemojis] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState(null);
    const [totalslide, setTotalslide] = useState(1);
    const [currentslide, setCurrentslide] = useState(1);
    const [openthisbutton, setOpenthisbutton] = useState(true);
    const [showthis, setShowthis] = useState(true);
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    });
    const [isValid, setIsValid] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        (async () => {
            const input = {
                id,
                groups: "selfstudy"
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        })()
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        setYourusername(vreachumnewerna)
        if(reachumnname){
            setIsOpen(false);
        }
        const thisss = sessionStorage.getItem("updatedusername");
        if(thisss){
            setShowthis(false);
        }
    }, []);
    useEffect(() => {
        async function fetchData() {
            const response = await API.graphql({
                query: getPlayslide,
                variables: { id }
            });
            const datas = response.data.getPlayslide;
            const h = datas.playlistlinks;
            let i = h.length;
            setTotalslide(i);
            if (count < i) {
                setUrls(h[count].rurl);
                setCurrentslide(count + 1)
            }else if(count >= i){
                setOpenthisbutton(false)
            }else {
                setCount(0)
            }
        }
        fetchData();
    }, [count,id]);
    const finalText = selectedEmoji ? `${yourusername} ${selectedEmoji}` : yourusername;
    const createfinalText = selectedEmoji ? `${stateData.meetname} ${selectedEmoji}` : stateData.meetname;

    const handleSubmit2 = async event => {
        event.preventDefault();
        if(isValid === true) {
            try {
                const ssname = stateData.meetname;
                const createfinalText = selectedEmoji ? `${ssname} ${selectedEmoji}` : ssname;
                const ssemail = stateData.meetemail;
                localStorage.setItem("vreachumnewername", createfinalText);
                localStorage.setItem("vreachumneweremail", ssemail);
                setShowthis(false);
                sessionStorage.setItem("updatedusername", "1")
            } catch (err) {
                console.log(err)
            }
        }
    }
    function ends(){
        history.push(`/`);
    }
    const handleChange222 = event => {
        const ev = event.target.value;
        setYourusername(ev);
    };
    function thisprivacy(){
        window.open("https://reachum.com/terms-and-conditions/", "_blank")
    }
    function openemoji(){
        if(openemojis === false) {
            setOpenemojis(true)
        }else{
            setOpenemojis(false)
        }
    }
    function handlereplay(){
        sessionStorage.removeItem("submitrid");
        window.location.reload()
    }

    return (
        <>
            <Helmet>
                <title>Self-Study</title>
            </Helmet>
        <div>
                    {urls !== "" && (
                        <iframe
                            className="preiframe"
                            id="theFrame"
                            src={urls}
                            loading="lazy"
                            width="100%"
                            height="100vh"
                            title="maincontent"
                            frameBorder="0"
                            allow="fullscreen"
                        />
                    )}
                <Grid container spacing={0} className="bottoms">
                    <Grid item xs={2} className="rightside">
                        {openthisbutton ?
                            <p className="greenselfthis">{currentslide} / {totalslide}</p>
                            :
                            <div className="upgradenew3" id="3">
                                <img src={Replaybutton} onClick={handlereplay} className="mimg" alt="Replay"/>
                                <span className="tooltiptext3">Restart</span>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={8} className="centerside">
                                    <img src={Left} className="mimg" onClick={() => {
                                        setCount(count - 1);
                                        if (count === 0) {
                                            setCount(0)
                                        }
                                    }} alt="left"/>
                                      &nbsp;
                                    <img src={Right} onClick={() => setCount(count + 1)} className="mimg" alt="right" />
                    </Grid>
                    <Grid item xs={2}>
                        <div className="endmeetingb">
                            <img src={Ending} onClick={ends} alt="exit"/>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Modal
                show={showthis}
                centered
            >
                <Modal.Body>
                    <div className="enteryournameform">
                        {isOpen ?
                            <Form className="market-header">
                                <Form.Item>
                                    <span> Enter Email*</span>
                                    <br/>
                                    <Input
                                        type="email"
                                        value={stateData.meetemail}
                                        id="email"
                                        onChange={meetemail => {
                                            setStateData({
                                                ...stateData,
                                                meetemail: meetemail
                                            });
                                            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                            const isValidemail = emailRegex.test(meetemail);
                                            setIsValid(isValidemail);
                                        }}
                                        placeholder="Type Your Email"
                                        maxLength={100}
                                        className="craeteroomgame"
                                    />
                                    {!isValid && <p style={{ color: '#d2196e' }}>Please enter a valid email address.</p>}
                                </Form.Item>
                                <Form.Item>
                                    <span> Create Display Name:</span>
                                    <br/>
                                    <Input
                                        type="text"
                                        value={stateData.meetname}
                                        onChange={meetname => setStateData({
                                            ...stateData,
                                            meetname: meetname
                                        })}
                                        maxLength={50}
                                        placeholder="Text"
                                        className="craeteroomgame"
                                    />
                                    <br/>
                                    Display Name with Emoji added: <br/> {createfinalText}
                                </Form.Item>
                                <Form.Item>
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                        </Grid>
                                        <Grid item xs={6} className="rightside">
                                            <Button
                                                disabled={!stateData.meetemail || !stateData.meetname}
                                                onClick={handleSubmit2}
                                                size="sm"
                                                className="ibuttnsform"
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form.Item>
                            </Form>
                            :
                            <div className="viewright">
                                <span>Current Display Name:</span>
                                <br/>
                                <input type="text" className="updatenameform" value={yourusername} name="message"
                                       onChange={handleChange222}/>
                                <br/>
                                <br/>
                                <p className="thisfontsize">New Display Name: <br/> {finalText}</p>
                                <Button className="updateformbutton" onClick={() => {
                                    localStorage.setItem("vreachumnewername", finalText);
                                    setShowthis(false);
                                    sessionStorage.setItem("updatedusername", "1")

                                }} size="sm">Submit</Button>
                                <Button variant="secondary" onClick={() => {
                                    setShowthis(false);
                                    sessionStorage.setItem("updatedusername", "1")
                                }
                                } className="updateformbutton" size="sm">Cancel</Button>
                                <br/>
                                <br/>
                            </div>
                        }
                        <div>
                            <p className="thisfontsize">Click any <span className="underscored" onClick={openemoji}>Emoji</span> to personalize your username  ͡👁️ ▭ ͡👁️. </p>
                            {openemojis ?
                                <Tabs
                                    defaultActiveKey="Emoticons"
                                    id="fill-tab-example"
                                    className="mb-3"
                                    fill
                                >
                                    <Tab eventKey="Emoticons" title="Emoticons">
                                        <EmojiPicker onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                    </Tab>
                                    <Tab eventKey="Food" title="Food">
                                        <EmojiPicker1 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                    </Tab>
                                    <Tab eventKey="Car" title="car">
                                        <EmojiPicker2 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                    </Tab>
                                    <Tab eventKey="Sport" title="Sport">
                                        <EmojiPicker3 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                    </Tab>
                                </Tabs>
                                : ""
                            }
                            <br/>
                        </div>
                        <p className="privacytext">
                            We respect your privacy. Data is stored for game and progress tracking only. REACHUM does not share information with third parties. Read our full privacy policy <span
                            className="privacytextspan" onClick={thisprivacy}>here</span>.</p>
                    </div>
                </Modal.Body>
            </Modal>
            </>
    );
}
export default SelfStudy;
