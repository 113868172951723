import React, {useState, useMemo, useEffect} from 'react';
import {API} from "aws-amplify";
import {listLeaderboards} from "../../../graphql/queries";
import {createEngagement, createLeaderboard, updateLeaderboard} from "../../../graphql/mutations";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../css/taptap.css";
import Thiscorrect from "../../../img/view/check-mark.png";
import Thiswrong from "../../../img/view/close.png";

function shuffle(items) {
  const entries = items.map((item) => [Math.random(), item])
  entries.sort((a, b) => a[0] - b[0])
  return entries.map((entry) => entry[1])
}

export default function Showitemlist() {
  const [quest, setQuest] = useState();
  const [timeval, setTimeval] = useState(0);
  const { id, rid } = useParams();
  const [messages, setMessages] = useState();
  const [thissaved, setThissaved] = useState([]);
  const [isShows, setIsShows] = useState(true);
  const [isshowreset, setIsshowreset] = useState(false);
  const [playpause, setPlaypause] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [submittedmessage, setSubmittedmessage] = useState("");
  const [counter, setCounter] = useState();
  const [showclock, setShowclock] = useState(false);
  useEffect(() => {
    const newCards=sessionStorage.getItem("sequencinggamesques");
    setQuest(newCards);
    const newcounter=Number(sessionStorage.getItem("sequencinggamescounter"));
    if(newcounter !== 0) {
      setCounter(newcounter);
      setShowclock(true);
    }else{
      setTimeval(40)
    }
    const submitrids = sessionStorage.getItem("submitrid");
    if(submitrids){
      setThissaved(submitrids)
      if(submitrids.includes(rid)){
        setDisabled(true);
        setSubmittedmessage("Submitted")
      }
    }
  }, []);
  const storedItems = useMemo(() => {
    const stored = sessionStorage.getItem('sequencinggames');
    if (stored == null) {
      return []
    }
    return JSON.parse(stored)
  }, [])
  const [items, setItems] = useState(() => {
    return shuffle(storedItems)
  })
  const defaultAnswers = items.map(() => {
    return {
      id: '',
      text: '',
      image: ''
    }
  })

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setTimeval(0)
      return <div className="timer">0</div>;
    }else{
      setTimeval(remainingTime)
    }
    return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
    );
  }
  const [activeItem, setActiveItem] = useState()

  const [answers, setAnswers] = useState(() => {
    return defaultAnswers
  })
  const answerViews = answers.map((answer, answerIndex) => {
    function handleClick () {
      if (activeItem == null) {
        return
      }
      setAnswers(currentAnswers => {
        const newAnswers = currentAnswers.map((currentAnswer, currentAnswerIndex) => {
          if (answerIndex === currentAnswerIndex) {
            return activeItem
          }
          return currentAnswer
        })
        return newAnswers
      })
      setActiveItem(undefined)
    }
    const key = answer.id !== '' ? answer.id : answerIndex
    const image = answer.image !== '' && (
        <img src={answer.image} style={{ width: '170px' }} />
    )
    const correctIcon = answer.correct === true && (<img className="correctandincorrect" src={Thiscorrect} width="20px"/>)
    const incorrectIcon = answer.correct === false && (<img className="correctandincorrect" src={Thiswrong} width="20px"/>)
    const correctAnswer = answer.correctAnswer != null && (
        <div style={{marginTop:"8px"}}><span style={{color: "#269b7b"}}> {answer.correctAnswer.text} </span> <img src={answer.correctAnswer.image} style={{ width: '100px' }} /></div>
    )
    return (
        <>
        <li key={key} onClick={handleClick} style={{fontSize: "20px", marginLeft:"8px", marginBottom:"8px", backgroundColor:"#f0f0f0", color:"#0c4128", padding:"5px"}}>
         <div className="centerthis">
          <>{correctIcon} {incorrectIcon}</>
          <span style={{marginLeft:"5px"}}>{answer.text}</span> &nbsp; &nbsp;
         {image}
         <br/>
           {correctAnswer}
         </div>
        </li>
          </>
    )
  })
  const remainingItems = items.filter(item => {
    const answered = answers.some(answer => answer.id === item.id)
    return !answered
  })
  const remainingViews = remainingItems.map(item => {
    function handleClick () {
      setActiveItem(item);
      setIsshowreset(true)
    }
    const isActive = activeItem != null && activeItem.id === item.id;
    const style = { margin: '5px', padding: '10px', fontSize:"20px", border:"1px solid #d2196e",borderRadius:"10px" }
    if (isActive) {
      style.background='#72b5a4';
      style.color="#ffffff";
      style.borderRadius="10px";
      style.transform="scale(1.5, 1.5)"
    }
    const image = item.image !== '' && (
        <img src={item.image} style={{ width: '170px' }} />
    )
    return (
        <div
            key={item.id}
            style={style}
            onClick={handleClick}
        >
          <span>{item.text}</span><br/>
          {image}
        </div>
    )
  })
  function handleReset () {
    setAnswers(defaultAnswers);
    setIsshowreset(false)
  }

  function handleSubmit() {
    const corrects = answers.filter((answer, answerIndex) => {
      const item = storedItems[answerIndex]
      return item.id === answer.id
    })
    const vv = corrects.length;
    const m = items.length;
    let tt = [];
    if (thissaved) {
      tt = [thissaved, rid]
    } else {
      tt = [rid]
    }
    sessionStorage.setItem("submitrid", tt);
    setPlaypause(false);
    let mymeetnames = localStorage.getItem("vreachumnewername");
    let mymeetemails = localStorage.getItem("vreachumneweremail");
    const ttr = timeval * vv;
    const cm = ttr / m;
    const scre = Math.floor(cm);
    if (scre > 0) {
      try {
        API.graphql({
          query: listLeaderboards,
          variables: {
            filter: {
              "meetemail": {
                eq: mymeetemails
              },
              "answerid": {
                eq: id
              }
            }
          }
        }).then(datas => {
          const plays = datas.data.listLeaderboards.items;
          const playsl = plays.length;
          if (playsl > 0) {
            const myscore = plays[0].score;
            const myid = plays[0].id;
            const myscores = scre + myscore;
            try {
              const input = {
                id: myid,
                score: myscores,
                thisgamescore: scre
              }
              API.graphql({
                query: updateLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          } else {
            try {
              const input = {
                answerid: id,
                name: mymeetnames,
                meetemail: mymeetemails,
                score: scre,
                thisgamescore: scre
              }
              API.graphql({
                query: createLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }
          setMessages(`You scored ${scre} points.`)
        })
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        API.graphql({
          query: listLeaderboards,
          variables: {
            filter: {
              "meetemail": {
                eq: mymeetemails
              },
              "answerid": {
                eq: id
              }
            }
          }
        }).then(datas => {
          const plays = datas.data.listLeaderboards.items;
          const playsl = plays.length;
          if (playsl === 0) {
            try {
              const input = {
                answerid: id,
                name: mymeetnames,
                meetemail: mymeetemails,
                score: 0,
                thisgamescore: 0
              }
              API.graphql({
                query: createLeaderboard,
                variables: {input}
              });

            } catch (err) {
              console.log(err)
            }
          } else {
            const myid = plays[0].id;
            try {
              const input = {
                id: myid,
                thisgamescore: 0
              }
              API.graphql({
                query: updateLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
      setMessages("You scored 0 points.")
    }
    try {
      const input = {
        questionid: id,
        engage: 1,
        people: 1
      }
      API.graphql({
        query: createEngagement,
        variables: {input}
      });
    } catch (err) {
      console.log(err)
    }
    const newAnswers = answers.map((currentAnswer, index) => {
      const correctAnswer = storedItems[index];
      const answeredCorrect = currentAnswer.text === correctAnswer.text;
      if (answeredCorrect) {
        return {
          ...currentAnswer,
          correct: true
        };
      } else {
        return {
          ...currentAnswer,
          correct: false,
          correctAnswer
        };
      }
    });
    setAnswers(newAnswers);
    setIsShows(false);
  }

  const getdatas = async () =>{
    const mymeetnams = localStorage.getItem("vreachumnewername");
    const mymeetemals = localStorage.getItem("vreachumneweremail");
    if(mymeetemals) {
      try {
        API.graphql({
          query: listLeaderboards,
          variables: {
            filter: {
              "meetemail": {
                eq: mymeetemals
              },
              "answerid": {
                eq: id
              }
            },
            limit:10000
          }
        }).then(datas => {
          const plays = datas.data.listLeaderboards.items;
          const playsl = plays.length;
          if (playsl === 0) {
            try {
              const input = {
                answerid: id,
                name: mymeetnams,
                meetemail: mymeetemals,
                score: 0,
                thisgamescore: 0
              }
              API.graphql({
                query: createLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }else{
            const myid = plays[0].id;
            try {
              const input = {
                id: myid,
                thisgamescore:0
              }
              API.graphql({
                query: updateLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }
          setMessages("You scored 0 points.")
          setIsShows(false);
          try {
            const input = {
              questionid: id,
              engage: 0,
              people: 1
            }
            API.graphql({
              query: createEngagement,
              variables: {input}
            });
          } catch (err) {
            console.log(err)
          }
        })
      } catch (err) {
        console.log(err)
      }
    }else{
      setMessages("Time is up.")
      setIsShows(false);
    }
  }

  return (
      <div className="fastanswerspage2">
        <div className="greenback">
          <Grid container spacing={0}>
            <Grid item xs={2}>
              {showclock ?
                  <div className="lpollheadclock2">
                    <CountdownCircleTimer
                        isPlaying={playpause}
                        duration={counter}
                        size={50}
                        colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                        colorsTime={[10, 6, 3, 0]}
                        onComplete={getdatas}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  </div>
                  : ""
              }
            </Grid>
            <Grid item xs={10}>
              <div className="fastanswes">
                <h2>{quest}</h2>
              </div>
            </Grid>
          </Grid>
        </div>
        <br/>
        <>
                {isShows ?
                <div style={{
                  display: 'flex',
                  flexWrap: "wrap",
                  width: "100%",
                  margin: "0 auto",
                  padding: "10px",
                  alignItems: "center",
                  backgroundColor: "#f0f0f0",
                  justifyContent: "center"
                }}>
                  {remainingViews}
                </div>
                :""
                }
                <br/>
                <br/>
                <ol style={{textAlign: "left", margin: "10px", width: "98%"}}>
                  {answerViews}
                </ol>
                <br/>
                {isShows ?
                    <>
                <Grid container spacing={0}>
                  <Grid item xs={1}>
                  </Grid>
                  <Grid item xs={5} className="ssleftside">
                    {isshowreset ?
                        <Button onClick={handleReset} variant="danger">Reset</Button>
                        : ""
                    }
                  </Grid>
                  <Grid item xs={5} className="rightside">
                    <p className="redthis">{submittedmessage}</p>
                    <Button onClick={handleSubmit} variant="success" disabled={isDisabled}>Submit</Button>
                  </Grid>
                  <Grid item xs={1}>
                  </Grid>
                </Grid>
                <br/>
                <br/>
                <br/>
              </> :
              <>
                <div>
                  <br/>
                  <p className="pollmaeeage">{messages}</p>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </>
          }
        </>
      </div>
  )
}
