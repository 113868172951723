import React from "react";

function Manageaccount () {
    const tt = sessionStorage.getItem("amycid")
    const ss = sessionStorage.getItem("amysstatus")
    const linkHtml =`<a href="javascript:void(0)" data-billsby-type="account" data-billsby-customer=${tt} data-billsby-subscription=${ss} class="pricingbutton">Subscription</a>`;
    return (
        <div className='App'>
            <div dangerouslySetInnerHTML={{ __html: linkHtml }} />
            <i className="icode">If the button is unresponsive, please reload the page and click it again.</i>
        </div>
    )
}

export default Manageaccount
