import React, { useEffect, useState, useRef } from "react";
import {API, Auth} from 'aws-amplify';
import {getPlayslide, listLeaderboards} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import {Button,Modal} from "react-bootstrap";
import {createLeaderboard, updateLeaderboard,createEngagement} from "../../../graphql/mutations";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Card from "./card";

const uniqueCardsArray = [
    {
        type: "1+1",
        text:"2"
    },
    {
        type: "2+2",
        text:"4"
    },
    {
        type: "3+3",
        text:"6"
    },
    {
        type: "4+4",
        text:"8"
    },
    {
        type: "5+5",
        text:"10"
    },
    {
        type: "6+6",
        text:"12"
    }
];

function shuffleCards(array) {
    const length = array.length;
    for (let i = length; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * i);
        const currentIndex = i - 1;
        const temp = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temp;
    }
    return array;
}
function Flipshow() {
    const [cards, setCards] = useState([]);
    const [playpause, setPlaypause] = useState(true);
    const [timeval, setTimeval] = useState(0);
    const [messages, setMessages] = useState();
    const [isShows, setIsShows] = useState(true);
    const [isShowswin, setIsShowswin] = useState(true);
    const { id, rid } = useParams();
    const [isShow, setIsShow] = useState(true);
    const [counter, setCounter] = useState();
    const [thissaved, setThissaved] = useState([]);
    const [submittedmessage, setSubmittedmessage] = useState("");
    const [show, setShow] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [showclock, setShowclock] = useState(false);
    useEffect(() => {
        const newCards=JSON.parse(localStorage.getItem("reachumflipcardgame"));
        setCards(newCards);
        const newcounter=Number(localStorage.getItem("reachumflipcardgametimer"));
        if(newcounter !== 0) {
            setCounter(newcounter);
            setShowclock(true);
        }else{
            setTimeval(40)
        }
        const typeCards = newCards.map(card => {
            return {...card, show: 'type'}
        })
        const textCards = newCards.map(card => {
            return {...card, show: 'text'}
        })
        const newShuffle = shuffleCards(typeCards.concat(textCards))
        setCards(newShuffle);
        const submitrids = sessionStorage.getItem("submitrid");
        if(submitrids){
            setThissaved(submitrids)
            if(submitrids.includes(rid)){
                setDisabled(true);
                setSubmittedmessage("Submitted")
            }
        }
    }, []);
    const [openCards, setOpenCards] = useState([]);
    const [clearedCards, setClearedCards] = useState({});
    const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);

    const timeout = useRef(null);

    const disable = () => {
        setShouldDisableAllCards(true);
    };
    const enable = () => {
        setShouldDisableAllCards(false);
    };

    const checkCompletion = () => {
        if (Object.keys(clearedCards).length === uniqueCardsArray.length) {
            setShow(true);
            setPlaypause(false);
        }
    };

    const evaluate = () => {
        const [first, second] = openCards;
        enable();
        if (cards[first].type === cards[second].type) {
            setTimeout(() => {
                setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
                setOpenCards([]);
            }, 1000);
            return;
        }
        // This is to flip the cards back after 500ms duration
        timeout.current = setTimeout(() => {
            setOpenCards([]);
        }, 600);
    };
    const handleCardClick = (index) => {
        if (openCards.length === 1) {
            setOpenCards((prev) => [...prev, index]);
            disable();
        } else {
            clearTimeout(timeout.current);
            setOpenCards([index]);
        }
    };

    useEffect(() => {
        let timeout = null;
        if (openCards.length === 2) {
            timeout = setTimeout(evaluate, 800);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [openCards]);

    useEffect(() => {
        checkCompletion();
    }, [clearedCards]);
    const checkIsFlipped = (index) => {
        return openCards.includes(index);
    };

    const checkIsInactive = (card) => {
        return Boolean(clearedCards[card.type]);
    };

    function senddata(){
        let tt = [];
        if (thissaved){
            tt = [thissaved, rid]
        }else{
            tt = [rid]
        }
        sessionStorage.setItem("submitrid", tt);
        checkCompletion();
        setIsShowswin(false);
            let mymeetnames = localStorage.getItem("vreachumnewername");
            let mymeetemails = localStorage.getItem("vreachumneweremail");

        try {
            API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "meetemail": {
                            eq: mymeetemails
                        },
                        "answerid": {
                            eq: id
                        }
                    },
                    limit:10000
                }
            }).then(datas => {
                const plays = datas.data.listLeaderboards.items;
                const playsl = plays.length;
                if (playsl > 0) {
                    const myscore = plays[0].score;
                    const myid = plays[0].id;
                    const myscores = timeval + myscore;
                    try {
                        const input = {
                            id: myid,
                            score: myscores,
                            thisgamescore: timeval
                        }
                        API.graphql({
                            query: updateLeaderboard,
                            variables: {input}
                        });
                    } catch (err) {
                        console.log(err)
                    }
                } else {
                    try {
                        const input = {
                            answerid: id,
                            name: mymeetnames,
                            meetemail:mymeetemails,
                            score: timeval,
                            thisgamescore: timeval
                        }
                        API.graphql({
                            query: createLeaderboard,
                            variables: {input}
                        });
                    } catch (err) {
                        console.log(err)
                    }
                }
                setMessages(`You scored ${timeval} points.`)
            })
        } catch (err) {
            console.log(err)
        }
        setIsShows(false);
        setIsShow(false);
        try {
            const input = {
                questionid: id,
                engage: 1,
                people: 1
            }
            API.graphql({
                query: createEngagement,
                variables: {input}
            });
        } catch (err) {
            console.log(err)
        }
    }
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setTimeval(0)
            return <div className="timer">0</div>;
        }else{
            setTimeval(remainingTime)
        }
        return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
        );
    }
    const getdatas = async () =>{
        const mymeetnams = localStorage.getItem("vreachumnewername");
        const mymeetemals = localStorage.getItem("vreachumneweremail");
        if(mymeetemals) {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "meetemail": {
                                eq: mymeetemals
                            },
                            "answerid": {
                                eq: id
                            }
                        },
                        limit: 10000
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl === 0) {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnams,
                                meetemail: mymeetemals,
                                score: 0,
                                thisgamescore: 0
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });

                        } catch (err) {
                            console.log(err)
                        }
                    }else{
                        const myid = plays[0].id;
                        try {
                            const input = {
                                id: myid,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                        setMessages(`You scored 0 points.`);
                        setIsShows(false);
                        setIsShow(false);
                        try {
                            const input = {
                                questionid: id,
                                engage: 0,
                                people: 1
                            }
                            API.graphql({
                                query: createEngagement,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                })
            } catch (err) {
                console.log(err)
            }
        }else{
            setMessages("Time is Up")
            setIsShows(false);
            setIsShow(false);
        }
    }

    return (
        <>
        <div className="flipcardpage">
            <div className="greenbackcenter">
                {showclock ?
                    <div className="lpollheadclockflip">
                        <CountdownCircleTimer
                            isPlaying={playpause}
                            duration={counter}
                            size={50}
                            colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                            colorsTime={[7, 5, 2, 0]}
                            onComplete={getdatas}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </div>
                    : ""
                }
            </div>
            <br/>
            <>
                {isShows ?
                    <>
                        <div className="flipcardpage">
                            <div className="game-board">
                                {cards.map((card, index) => {
                                    return (
                                        <Card
                                            key={index}
                                            card={card}
                                            index={index}
                                            isDisabled={shouldDisableAllCards}
                                            isInactive={checkIsInactive(card)}
                                            isFlipped={checkIsFlipped(index)}
                                            onClick={handleCardClick}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </> :
                    <div className="fastanswers">
                        <p className="pollmaeeage">{messages}</p>
                    </div>
                }
                </>
            <Modal show={show}>
                <Modal.Body>
                    <div className="flipcenter">
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h3>{messages}</h3>
                        {isShowswin ?
                            <div className="cccenter">
                            <p className="redthis">{submittedmessage}</p>
                            <Button variant="warning" onClick={senddata} disabled={isDisabled} id="pollsubmit">Click
                                here to get points</Button>
                            </div>
                            : ""
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
            </>
    );
}

export default Flipshow;
