import {API, Auth} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide} from "../../graphql/queries";
import {updatePlayslide} from "../../graphql/mutations";
import {useParams,useHistory} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import Login from "../../pages/login";
import Grid from '@material-ui/core/Grid';
import Right from "../../img/host/next-btn.png";
import Left from "../../img/host/back-btn.png";
import Full from "../../img/host/Layout.png";
import "../css/mroom.css";
import Playb from "../../img/host/play-btn.png";
import Pauseb from "../../img/host/pause-btn.png";
import Ending from "../../img/host/Exit-Button.png";
import {Helmet} from "react-helmet";
import Timeron from "../../img/host/Timer.png";
import Noi from "../../img/no.png";
import Lists from "../../img/host/List.png";
import {newUpdatePlayslide} from "../../graphql/subscriptions";
import Draggable from "react-draggable";
import ModalDialog from "react-bootstrap/ModalDialog";
import Modal from "react-bootstrap/Modal";
import Alldirections from "../../img/host/all-directions.png";
import {Button} from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".modal-title"><ModalDialog {...this.props} /></Draggable>
    }
}

function EmojiPicker({ onSelect }) {
    const emojis = ["(ง︡'-'︠)ง", "≧◉ᴥ◉≦", "ʕ•́ᴥ•̀ʔっ", "✍(◔◡◔)", "(っ＾▿＾)💨", "(❛‿❛✿̶̥̥)","(^◡^ )", "(ɔ◔‿◔)ɔ ♥", "(>‿◠)✌", "( ͡❛ ͜ʖ ͡❛)",
        "(.•̃ ᴗ•̃.)","(╥﹏╥)","(ᵔ̃ ⏥ᵔ̃)","( ◡́.◡̀)",
        "(ᵔ̃ ‿‿ᵔ̃)","(ᵔ̃ ‿ᵔ̃)","( ͠👁️ ͜ʖ ͡👁️)","( ͠ᵔ ͜ʖ ͡ᵔ)","(ᵔ̃ ͜ʖᵔ̃)","（ つ︣﹏╰）",
        "( ͡❛ ‿‿ ͡❛)","( ͡° ‿‿ ͡°)","( ͡ᵔ ‿‿ ͡ᵔ)",
        "(.•̃ ⏥•̃.)","(͡° ͜ʖ ͡°)","(.👁️̃ ⏥👁️̃.)","(◑_◑)","¯\\_( ͠👁️ ͜ʖ ͡👁️)_/¯","(̶◉͛‿◉̶)","(.•̃ ͜ʖ•̃.)","¯\\_( ͠~ ͜ʖ ͡°)_/¯","(.°̃ ͜ʖ°̃.)","(. •︠ ͜ʖ ︡•.)",
        "(. •︠ ⏥ ︡•.)","( ͡ᵔ ‿‿͡ᵔ )","(ᵔ̃ ‿‿ᵔ̃)","(👁️̃ ‿‿👁️̃)","ಥ_ಥ","ᕙ(`▿´)ᕗ",
        "¯\\_(•̃ ⏥•̃)_/¯","( •︣ ▿ •︣ )","( ͡👁️ ‿‿ ͡👁️)","(ㆆ_ㆆ)","(👁️̃ ‿‿👁️̃)","( ͠ᵔ ‿‿ ͡ᵔ)✌","( ͠ᵔ ͜ʖ ͡ᵔ)✌","( ˘︹˘ )","t(>.<t)","(≖_≖ )","(͠◉_◉᷅ )","(◑́_◑᷅ )", "( ͡• ▿ ͡•)"];
    return (
        <div>
            {emojis.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker1({ onSelect }) {
    const emojis1 = ["🥪", "🥙", "🥯", "🍖", "☕", "🥛", "🥚", "🍠", "🍥", "🍓",
        "🍳","🍱", "🍤","🥤","🧋", "🍷","🍹","🍸","🍻","🍺","🍦",
        "🍨","🍧","🎂","🍰","🍯","🍫","🍬","🍭","🍡","🍩","🍪", "🍘","🍮","🍌","🍉","🍊","🍍","🍎","🍏","🍑","🍋",
        "🍈","🍐","🍒","🍇","🍅","🍆","🌽","🍣","🍚","🍛","🍙","🍲","🍜","🍝","🍢","🍞","🍗","🍟","🍔","🍕","🍄"];
    return (
        <div>
            {emojis1.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker2({ onSelect }) {
    const emojis2 = ["🚚", "🚔", "🚑", "🚓", "🚒", "🚕", "🚖", "🚛",
        "🚐","🚗", "🚘","🚙","🚜", "🚉","🚊","🚇","🚥","🚆","🚞","🚲","🏍","🦽","🦼","🛺","🛵","🛻","🏎","🚍","⛽",
        "🚄","🚅","🚝","🚈","🚠","🚡","🚎","🚃","🚋","🚌","✈", "🚁","⚓","🚣","⛵","🏄","🏊","🚀","🛴"];
    return (
        <div>
            {emojis2.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker3({ onSelect }) {
    const emojis3 = ["⚽", "(づ˶•༝•˶)づ  🏀", "🤺","⚾","🥎","🏐","🏈","🏉","🎾","🥏","🎳","🏏","🏑","🏒","🥍","🏓","🏸","🥊","🥋",
        "🥅","⛳","⛸️","🎣","🤿","🎽","🎿","🛷","🥌"];
    return (
        <div>
            {emojis3.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function Comroom() {
    const [urls, setUrls] = useState([]);
    const [lurl, setLurl] = useState("");
    const [user, setUser] = useState();
    const [lsize, setLsize] = useState(3);
    const [rsize, setRsize] = useState(9);
    const [isShow, setIsShow] = useState(true);
    const [isfull, setIsfull] = useState(false);
    const [isShows, setIsShows] = useState(true);
    const [isOpen, setIsOpen] = useState(true);
    const [openemojis, setOpenemojis] = useState(false);
    const [mylists, setMylists] = useState([]);
    const [show, setShow] = useState(false);
    const [yourusername, setYourusername] = useState('');
    const [selectedEmoji, setSelectedEmoji] = useState(null);
    const handleClose = () => setShow(false);
    const [showthis, setShowthis] = useState(true);
    const finalText = selectedEmoji ? `${yourusername} ${selectedEmoji}` : yourusername;
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        setYourusername(vreachumnewerna)
        if(!reachumnname){
            history.push(`/cform/${id}`);
        }
        const thisss = sessionStorage.getItem("updatedusername");
        if(thisss){
            setShowthis(false);
        }
    }, []);
    const { id } = useParams();
    let history = useHistory();
    useEffect(() => {
        (async () => {
            const input = {
                id,
                groups: "noselfstudy"
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        })()
    }, []);
    useEffect(  () => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
            })
            .catch((err) => console.log(err));
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        });
        async function fetchData() {
            try {
                const response = await API.graphql({
                    query: getPlayslide,
                    variables:{id}
                })
                const datas = response.data.getPlayslide;
                setUrls(datas.clink);
                const h = datas.playlistlinks;
                const lists = [];
                const ilists = h.length; // playlist length
                for (let j = 0; j < ilists; j++) {
                    const parray = h[j].rurl;
                    lists.push(parray)
                };
                setMylists(lists);
                const v = datas.aplaypause;
                if(v === true){
                    const thetimer = document.getElementById('theFrame');
                    thetimer.src =  "/timer";
                }else{
                    setUrls(datas.clink);
                }
                const fuls = datas.fulls;
                if(fuls === true){
                    setLsize(12);
                    setRsize(12);
                    setIsOpen(false);
                    setIsShow(false);
                }else{
                    setLsize(3);
                    setRsize(9);
                    setIsOpen(true);
                    setIsShow(true);
                };
                const isplay = datas.vplaypause;
                if(isplay === true){
                    setIsShows(false)
                    const theFrame = document.getElementById('theFrame');
                    const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                    theFrameDocument.getElementById('reactplayer').setAttribute("playing", true);
                }else{
                    setIsShows(true)
                    const theFrame = document.getElementById('theFrame');
                    const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                    theFrameDocument.getElementById('reactplayer').setAttribute("playing", false);
                }
            }catch(error){
                console.log("hi")
            }
        }
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    async function fetchvlick() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const vreachumnewerna = localStorage.getItem("vreachumnewername");
            const m = `${datas.myrlink}?embed&logo=on&screenshare=on&leaveButton=off&background=off&recording&breakout=on&pipButton=on&roomIntegrations=on&autoSpotlight&displayName=${vreachumnewerna}`;
            setLurl(m);
            setUrls(datas.clink)
            const h = datas.playlistlinks;
            const lists = [];
            const ilists = h.length; // playlist length
            for (let j = 0; j < ilists; j++) {
                const parray = h[j].rurl;
                lists.push(parray)
            };
            setMylists(lists);
           let count = Number(datas.counts);
            if (count > 0) {
                setUrls(h[count].rurl);
            }else {
                const input = {
                    id,
                    aplaypause:false,
                    clink: h[0].rurl
                };
                await API.graphql({
                    query: updatePlayslide,
                    variables: {input}
                });
            }
            const fuls = datas.fulls;
            if(fuls === true){
                setLsize(12);
                setRsize(12);
                setIsOpen(false);
                setIsShow(false);
            }else{
                setLsize(3);
                setRsize(9);
                setIsOpen(true);
                setIsShow(true);
            };
        }catch(error){
            console.log("hi")
        }
    }
    const handleChange222 = event => {
        const ev = event.target.value;
        setYourusername(ev);
    };

    const goups = async () =>{
        const re = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = re.data.getPlayslide;
        const f = Number(datas.counts);
        const t = f+1;
        const h = datas.playlistlinks;
        const ilists = h.length; // playlist length
        let i = ilists - 1;
        if (t <= i) {
            const input = {
                id,
                aplaypause:false,
                counts:t,
                clink: h[t].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }else {
            const input = {
                id,
                aplaypause:false,
                counts:0,
                clink: h[0].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }
    }
    const godowns = async ()=>{
        const re = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = re.data.getPlayslide;
        const f = Number(datas.counts);
        const t = f-1;
        const h = datas.playlistlinks;
        if (t <= 0) {
            const input = {
                id,
                aplaypause:false,
                counts:0,
                clink: h[0].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }else {
            const input = {
                id,
                aplaypause:false,
                counts:t,
                clink: h[t].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }
    }
    const handleplay = async () =>{
        const isPlay = true;
        const input = {
            id,
            vplaypause:isPlay
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
    }
    const handlepause = async () =>{
        const isPlay = false;
        const input = {
            id,
            vplaypause:isPlay
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
    }
    const handlefullscreen = async () =>{
        const fulls = true;
        const ts = {id};
        const input = {
            id: ts.id,
            fulls:fulls
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setLsize(12);
        setRsize(12);
        setIsShow(false);
        setIsfull(true);
        hidebuttons()
    }
    const handlesmallscreen = async () =>{
        const fulls = false;
        const tds = {id};
        const input = {
            id: tds.id,
            fulls:fulls
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setLsize(3);
        setRsize(9);
        setIsShow(true);
        setIsfull(false);
        showbuttons()
    }
    const renderSlides = () => {
        return (
            <>
                    {mylists.map((slideContent, index) => (
                        <div className="slides" key={index}>
                                <img src={Noi} className="noi" width="125" height="72" onClick={ () => {
                                    const input = {
                                        id,
                                        aplaypause:false,
                                        clink: slideContent
                                    };
                                    API.graphql({
                                        query: updatePlayslide,
                                        variables: {input}
                                    });
                                    const thelist = document.getElementById('theFrame');
                                    thelist.src = slideContent;
                                }} />
                                <iframe src={slideContent} scrolling="no" className="editsframe" loading="lazy"></iframe>
                            </div>
                    ))}
            </>
        );
    }
    useHotkeys('n',()=>{
        goups()
    })
    useHotkeys('b',()=>{
       godowns()
    })
    useHotkeys('t',()=>{
        timeron()
    })
    useHotkeys('.', () => {
        if (isShows === false) {
            handlepause()
        }else{
            handleplay()
        }
    },{}, [isShows])

    useHotkeys('/', () => {
        if (isfull === false) {
            handlefullscreen()
        }else{
            handlesmallscreen()
        }
    },{}, [isfull])
    const timeron = async () =>{
        const input = {
            id,
            aplaypause:true
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
    }
    function hidebuttons() {
        setIsOpen(false)
    }
    function showbuttons() {
        setIsOpen(true)
    }
    const ends  = async() =>{
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history.push(`/`);
        }
    }
    function thisprivacy(){
        history.push("/termandConditions");
    }
    function openemoji(){
        if(openemojis === false) {
            setOpenemojis(true)
        }else{
            setOpenemojis(false)
        }
    }
    return (
        <>
            <Helmet>
                <title>Co-HOST</title>
            </Helmet>
            {!user ?
                <Login />
                :(
                        <>
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs={lsize}>
                                        {lurl !== "" && (
                                            <iframe
                                                className="mviframe"
                                                id="leftsid"
                                                src={lurl}
                                                loading="lazy"
                                                width="100%"
                                                scrolling="no"
                                                height="100vh"
                                                frameBorder="0"
                                                allow="camera; microphone; fullscreen; speaker; display-capture"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={rsize}>
                                        {urls !== "" && (
                                            <iframe
                                                className="mviframe"
                                                id="theFrame"
                                                src={urls}
                                                loading="lazy"
                                                width="100%"
                                                height="100vh"
                                                frameBorder="0"
                                                allow="fullscreen"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} className="bottoms">
                                    {isShow ?
                                        <Grid item xs={4} className="rightside">

                                                <img src={Full} onClick={handlefullscreen} className="mimg"/>

                                        </Grid>
                                        :
                                        <Grid item xs={4} className="rightside">

                                                <img src={Full} onClick={handlesmallscreen} className="mimg"/>

                                        </Grid>
                                    }

                                    <Grid item xs={6} className="leftside">
                                        {isOpen ?
                                            <>
                                                <div className="upgradenew3">
                                                    <img src={Lists} onClick={()=>{setShow(true)}} className="mimg" />
                                                    <span className="tooltiptext3">Content List. Click on any slide to show</span>
                                                </div>

                                                    <img src={Timeron} onClick={timeron} className="mimg" />

                                                {isShows ?

                                                        <img src={Playb} onClick={handleplay} className="mimg"/>

                                                    :

                                                        <img src={Pauseb} onClick={handlepause} className="mimg"/>

                                                }

                                                    <img src={Left} className="mimg" onClick={godowns}/>

                                                    <img src={Right} onClick={goups} className="mimg"/>

                                            </> : ""
                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="endmeetingb">
                                            <img src={Ending} onClick={ends} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Modal
                                size="lg"
                                dialogAs={DraggableModalDialog}
                                show={show}
                                onHide={handleClose}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><img className="dragme" src={Alldirections} /></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {renderSlides()}
                                </Modal.Body>
                                <Modal.Footer >
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={showthis}
                                centered
                            >
                                <Modal.Body>
                                    <div className="enteryournameform">
                                            <div className="viewright">
                                                <span>Current Username:</span>
                                                <br/>
                                                <input type="text" className="updatenameform" value={yourusername} name="message"
                                                       onChange={handleChange222}/>
                                                <br/>
                                                <br/>
                                                <p className="thisfontsize">New Username: <br/> {finalText}</p>
                                                <Button className="updateformbutton" onClick={() => {
                                                    localStorage.setItem("vreachumnewername", finalText);
                                                    setShowthis(false);
                                                    sessionStorage.setItem("updatedusername", "1")

                                                }} size="sm">Submit</Button>
                                                <Button variant="secondary" onClick={() => {
                                                    setShowthis(false);
                                                    sessionStorage.setItem("updatedusername", "1")
                                                }
                                                } className="updateformbutton" size="sm">Cancel</Button>
                                                <br/>
                                                <br/>
                                            </div>
                                        <div>
                                            <p className="thisfontsize">Click any <span className="underscored" onClick={openemoji}>Emoji</span> to personalize your username  ͡👁️ ▭ ͡👁️. </p>
                                            {openemojis ?
                                            <Tabs
                                                defaultActiveKey="Emoticons"
                                                id="fill-tab-example"
                                                className="mb-3"
                                                fill
                                            >
                                                <Tab eventKey="Emoticons" title="Emoticons">
                                                    <EmojiPicker onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                                </Tab>
                                                <Tab eventKey="Food" title="Food">
                                                    <EmojiPicker1 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                                </Tab>
                                                <Tab eventKey="Car" title="car">
                                                    <EmojiPicker2 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                                </Tab>
                                                <Tab eventKey="Sport" title="Sport">
                                                    <EmojiPicker3 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                                </Tab>
                                            </Tabs>
                                                : ""
                                            }
                                            <br/>
                                        </div>
                                        <p className="privacytext">
                                            We respect your privacy. Data is stored for game and progress tracking only. REACHUM does not share information with third parties. Read our full privacy policy <span
                                            className="privacytextspan" onClick={thisprivacy}>here</span>.</p>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                )
            }
        </>
    );
}
export default Comroom;
