import {API, Auth} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide} from "../../graphql/queries";
import {updatePlayslide} from "../../graphql/mutations";
import {useParams,useHistory} from "react-router-dom";
import Loginmobile from "../../pages/login-mobile";
import Grid from '@material-ui/core/Grid';
import Right from "../../img/view/next.svg";
import Left from "../../img/view/back.svg";
import "../css/mroom.css";
import Playb from "../../img/view/vplay.svg";
import Pauseb from "../../img/view/vpause.svg";
import Ending from "../../img/view/exit.svg";
import {Helmet} from "react-helmet";
import {newUpdatePlayslide} from "../../graphql/subscriptions";
import Content from "../../img/view/content.svg";
import People from "../../img/view/people.svg";
import ReactSwipe from "react-swipe";

function Mroommobile() {
    const [urls, setUrls] = useState([]);
    const [lurl, setLurl] = useState("");
    const [user, setUser] = useState();
    const [isinmobile, setIsinmobile] = useState(false);
    const [isopenthiscontent, setIsopenthiscontent] = useState(true);
    const [isShows, setIsShows] = useState(true);
    const { id } = useParams();
    let history = useHistory();
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    function getCurrentDimension(){
        return {
            height: window.innerHeight
        }
    }
    const rechumemail = localStorage.getItem("vreachumneweremail")
    if(rechumemail){
        localStorage.removeItem("vreachumnewername");
        localStorage.removeItem("vreachumneweremail")
    }
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    let reactSwipeEl;
    useEffect(  () => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
            })
            .catch((err) => console.log(err));
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        });
        async function fetchData() {
            try {
                const response = await API.graphql({
                    query: getPlayslide,
                    variables:{id}
                })
                const datas = response.data.getPlayslide;
                setUrls(datas.clink);
                const h = datas.playlistlinks;
                const isplay = datas.vplaypause;
                if(isplay === true){
                    setIsShows(false)
                    const theFrame = document.getElementById('theFrame');
                    const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                    theFrameDocument.getElementById('reactplayer').setAttribute("playing", true);
                }else{
                    setIsShows(true)
                    const theFrame = document.getElementById('theFrame');
                    const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                    theFrameDocument.getElementById('reactplayer').setAttribute("playing", false);
                }
            }catch(error){
                console.log("hi")
            }
        }
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    async function fetchvlick() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const m = datas.myrlink + "&embed&logo=on&leaveButton=on&background=off&breakout=on&roomIntegrations=off&locking=on&settingsButton=on&participantCount=on&autoSpotlight&screenshare=off";
            setLurl(m);
            setUrls(datas.clink)
            const h = datas.playlistlinks;
           let count = Number(datas.counts);
            if (count > 0) {
                setUrls(h[count].rurl);
            }else {
                const input = {
                    id,
                    aplaypause:false,
                    clink: h[0].rurl
                };
                await API.graphql({
                    query: updatePlayslide,
                    variables: {input}
                });
            }
        }catch(error){
            console.log("hi")
        }
    }
    const goups = async () =>{
        const re = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = re.data.getPlayslide;
        const f = Number(datas.counts);
        const t = f+1;
        const h = datas.playlistlinks;
        const ilists = h.length; // playlist length
        let i = ilists - 1;
        if (t <= i) {
            const input = {
                id,
                aplaypause:false,
                counts:t,
                clink: h[t].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }else {
            const input = {
                id,
                aplaypause:false,
                counts:0,
                clink: h[0].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }
    }
    const godowns = async ()=>{
        const re = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = re.data.getPlayslide;
        const f = Number(datas.counts);
        const t = f-1;
        const h = datas.playlistlinks;
        const ilists = h.length; // playlist length
        let i = ilists - 1;
        if (t <= 0) {
            const input = {
                id,
                aplaypause:false,
                counts:0,
                clink: h[0].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }else {
            const input = {
                id,
                aplaypause:false,
                counts:t,
                clink: h[t].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }
    }
    const handleplay = async () =>{
        const isPlay = true;
        const input = {
            id,
            vplaypause:isPlay
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
    }
    const handlepause = async () =>{
        const isPlay = false;
        const input = {
            id,
            vplaypause:isPlay
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
    }
    const ends  = async() =>{
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history.push(`/m`);
        }
    }
    return (
        <>
            <Helmet>
                <title>Mobile HOST</title>
            </Helmet>
            {!user ?
                <Loginmobile />
                :(
                        <>
                            <div className="thismfullpage">
                                <ReactSwipe
                                    className="carousel"
                                    swipeOptions={{ continuous: true }}
                                    ref={el => (reactSwipeEl = el)}
                                >
                            <div>
                                        {lurl !== "" && (
                                            <iframe
                                                className="fullmpage"
                                                id="leftsid"
                                                src={lurl}
                                                width="100%"
                                                scrolling="no"
                                                height={screenSize.height}
                                                frameBorder="0"
                                                allow="camera; microphone; fullscreen; speaker; display-capture"
                                            />
                                        )}
                            </div>
                                    <div>
                                        {urls !== "" && (
                                            <iframe
                                                className="fullmpage"
                                                id="theFrame"
                                                src={urls}
                                                width="100%"
                                                height={screenSize.height}
                                                frameBorder="0"
                                                allow="fullscreen"
                                            />
                                        )}
                                    </div>
                                </ReactSwipe>
                            </div>
                                <Grid container spacing={0} className="mobilebottoms">
                                    <Grid item xs={3}>
                                        {isopenthiscontent ?
                                            <img src={Content} onClick={() => {reactSwipeEl.next();setIsopenthiscontent(false);setIsinmobile(true)}} width="54" className="mobottom" />
                                            :
                                            <img src={People} onClick={() => {reactSwipeEl.next();setIsopenthiscontent(true);setIsinmobile(false)}} width="54" className="mobottom" />
                                        }
                                    </Grid>
                                    {isinmobile ?
                                        <>
                                            <Grid item xs={2} className="rightside">
                                                {isShows ?
                                                    <img src={Playb} onClick={handleplay} className="mobileimg"/>
                                                    :
                                                    <img src={Pauseb} onClick={handlepause} className="mobileimg"/>
                                                }
                                            </Grid>
                                            <Grid item xs={2} className="cccenter">
                                                <img src={Left} className="mobileimg" onClick={godowns}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <img src={Right} onClick={goups} className="mobileimg"/>
                                            </Grid>
                                        </> : ""
                                    }

                                    <Grid item xs={3} className="rightside">
                                        <img src={Ending} onClick={ends} className="mothismobileexit"/>
                                    </Grid>
                                </Grid>
                        </>
                )
            }
        </>
    );
}
export default Mroommobile;
