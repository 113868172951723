import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateFasteranswer} from '../../graphql/mutations';
import { useForm } from "react-hook-form";
import {Table} from "react-bootstrap";
import {getFasteranswer, getPlayslide} from "../../graphql/queries";
import {useParams} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "./style.css";
import Grid from "@material-ui/core/Grid";
import Resizer from "react-image-file-resizer";
const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            400,
            400,
            "JPEG",
            95,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
function Mediaflipcardedit() {
    const { id,rid } = useParams();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [thisimage, setThisimage] = useState('')
    const [thisname, setThisname] = useState("")
    const [thisgimage, setThisgimage] = useState('')
    const [selectedFile, setSelectedFile] = useState("");
    const [uploadedmessage, setUploadedmessage] = useState("");
    const d = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.username)))
            .catch(err => console.log(err));
        (async() => {
            const responses = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = responses.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);
    useEffect( () => {
        (async() => {
            const response = await API.graphql({
                query: getFasteranswer,
                variables:{id:rid}
            })
            const datas = response.data.getFasteranswer;
            const nname = datas.ffname;
            setThisname(nname)
            const tt = datas.fanswer;
            if(tt!== null){
                setThisgimage(tt)
            }
            const mt = datas.qmathstate;
            if(mt!== null){
                setSelectedFile(mt)
            }
            const mimage = datas.fquestion;
            if(mimage !== null){
                setThisimage(mimage)
            }
        })()
    }, [id]);
    const handleFileChange = async (event) => {
        const img = event.target.files[0];
        const resized = await resizeFile(img);
        setSelectedFile(resized)
    };
    const {register, handleSubmit} = useForm({});

    const onSubmit = async (data) => {
        try {
            const input = {
                id:rid,
                fquestion:data.fquestion,
                fimage:selectedFile,
                fanswer:data.fanswer
            }
            await API.graphql({
                query: updateFasteranswer,
                variables: {input}
            });
            setUploadedmessage("Updated")
        } catch (err) {
            console.log(err)
        }
    }

    return (
        isOwner === isUser ?
        <>
            <br/>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                Card name : &nbsp;
                            </Grid>
                            <Grid item xs={9}>
                                {thisname}
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                Front: &nbsp;
                            </Grid>
                            <Grid item xs={9}>
                                <input
                                    name="fanswer"
                                    defaultValue={thisgimage}
                                    ref={register({ required: true, maxLength: 200})}
                                    className="ques"
                                />
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                               Back : &nbsp;
                            </Grid>
                            <Grid item xs={9}>
				<textarea
                    name="fquestion"
                    defaultValue={thisimage}
                    ref={register({ required: true, maxLength: 500})}
                    className="ques"
                />
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                Image : &nbsp;
                            </Grid>
                            <Grid item xs={9}>
                                <input type="file" onChange={handleFileChange} />
                            </Grid>
                        </Grid>
                        <br/>
                        <div className="cccenter">
                        <img src={selectedFile} />
                        </div>
                        <br/>
                        <Table>
                            <tbody>
                            <tr><td>
                            </td>
                                <td className="orderright">
                                    <span className="redthis"> {uploadedmessage} </span><input type="submit" className="answersubmit" value="Update"/>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </form>
            </>
            :""
    );
}
export default Mediaflipcardedit;
