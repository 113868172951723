import React, {useEffect,useState} from "react";
import { API} from 'aws-amplify';
import {getFlipcard} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/correctanswerpage.css";

function Flipcardcorrctanswer() {
    const [thisqq, setThisqq] = useState([]);
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getFlipcard,
            variables:{id:rid}
        })
        const datas = response.data.getFlipcard;
       const qq = datas.fwords;
       setThisqq(qq);
    }

    return (
            <div className="correctanswerpage">
            <div className="corrctcenterthis">
                <p>The correct answers are: </p>
                <div className="cccenterfilpcard">
                        <div>
                        {thisqq.map((item, index) =>{
                            return(
                                <h2 key={index}>
                                    <span>
                                 {item.type}
                                </span>
                                    <span> = </span>
                                    <span>
                                        {item.text}
                                    </span>
                                </h2>
                            )
                        })
                        }
                        </div>
                </div>
            </div>
            </div>
    );
}

export default Flipcardcorrctanswer;
