/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHost = /* GraphQL */ `
  query GetHost($id: ID!) {
    getHost(id: $id) {
      id
      owner
      email
      capacity
      usage
      status
      refer
      createdAt
      updatedAt
    }
  }
`;
export const listHosts = /* GraphQL */ `
  query ListHosts(
    $filter: ModelHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        email
        capacity
        usage
        status
        refer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlayslide = /* GraphQL */ `
  query GetPlayslide($id: ID!) {
    getPlayslide(id: $id) {
      id
      meetingname
      playlistlinks {
        rid
        title
        rurl
        lurl
        eurl
        vtimer
        adobeprojectid
        file {
          bucket
          key
          region
        }
      }
      myrlink
      clientrlink
      ctime
      counts
      mid
      clink
      exiton
      fulls
      groups
      vplaypause
      aplaypause
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listPlayslides = /* GraphQL */ `
  query ListPlayslides(
    $filter: ModelPlayslideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayslides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        meetingname
        playlistlinks {
          rid
          title
          rurl
          lurl
          eurl
          vtimer
          adobeprojectid
        }
        myrlink
        clientrlink
        ctime
        counts
        mid
        clink
        exiton
        fulls
        groups
        vplaypause
        aplaypause
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFolders = /* GraphQL */ `
  query GetFolders($id: ID!) {
    getFolders(id: $id) {
      id
      folderlists
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listFolders = /* GraphQL */ `
  query ListFolders(
    $filter: ModelFoldersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        folderlists
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDesktopitems = /* GraphQL */ `
  query GetDesktopitems($id: ID!) {
    getDesktopitems(id: $id) {
      id
      deskitems
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listDesktopitems = /* GraphQL */ `
  query ListDesktopitems(
    $filter: ModelDesktopitemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesktopitems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deskitems
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPptxFile = /* GraphQL */ `
  query GetPptxFile($id: ID!) {
    getPptxFile(id: $id) {
      id
      name
      uploadedFile
      status
      images {
        name
        location
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPptxFiles = /* GraphQL */ `
  query ListPptxFiles(
    $filter: ModelPptxFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPptxFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        uploadedFile
        status
        images {
          name
          location
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGiphyGif = /* GraphQL */ `
  query GetGiphyGif($id: ID!) {
    getGiphyGif(id: $id) {
      id
      title
      url
      createdAt
      updatedAt
    }
  }
`;
export const listGiphyGifs = /* GraphQL */ `
  query ListGiphyGifs(
    $filter: ModelGiphyGifFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiphyGifs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPolllist = /* GraphQL */ `
  query GetPolllist($id: ID!) {
    getPolllist(id: $id) {
      id
      pfname
      ptimer
      pquestion
      pimage
      ptest {
        id
        panswer
        pimage
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPolllists = /* GraphQL */ `
  query ListPolllists(
    $filter: ModelPolllistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolllists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pfname
        ptimer
        pquestion
        pimage
        ptest {
          id
          panswer
          pimage
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPolls = /* GraphQL */ `
  query GetPolls($id: ID!) {
    getPolls(id: $id) {
      id
      PolllistId
      panswer
      createdAt
      updatedAt
    }
  }
`;
export const listPolls = /* GraphQL */ `
  query ListPolls(
    $filter: ModelPollsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        PolllistId
        panswer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFasteranswer = /* GraphQL */ `
  query GetFasteranswer($id: ID!) {
    getFasteranswer(id: $id) {
      id
      ftimer
      ffname
      fquestion
      fimage
      fanswer
      createdAt
      updatedAt
    }
  }
`;
export const listFasteranswers = /* GraphQL */ `
  query ListFasteranswers(
    $filter: ModelFasteranswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFasteranswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ftimer
        ffname
        fquestion
        fimage
        fanswer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOptionslist = /* GraphQL */ `
  query GetOptionslist($id: ID!) {
    getOptionslist(id: $id) {
      id
      pfname
      ptimer
      pquestion
      pimage
      ptest {
        id
        panswer
        pimage
      }
      oanswer
      createdAt
      updatedAt
    }
  }
`;
export const listOptionslists = /* GraphQL */ `
  query ListOptionslists(
    $filter: ModelOptionslistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptionslists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pfname
        ptimer
        pquestion
        pimage
        ptest {
          id
          panswer
          pimage
        }
        oanswer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTextslide = /* GraphQL */ `
  query GetTextslide($id: ID!) {
    getTextslide(id: $id) {
      id
      pfname
      ptest {
        id
        plist
      }
      titlecolor
      listcolor
      bgcolor
      createdAt
      updatedAt
    }
  }
`;
export const listTextslides = /* GraphQL */ `
  query ListTextslides(
    $filter: ModelTextslideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTextslides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pfname
        ptest {
          id
          plist
        }
        titlecolor
        listcolor
        bgcolor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDraganddrop = /* GraphQL */ `
  query GetDraganddrop($id: ID!) {
    getDraganddrop(id: $id) {
      id
      dname
      dtimer
      dquestion
      categories {
        name
        items {
          text
          image
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDraganddrops = /* GraphQL */ `
  query ListDraganddrops(
    $filter: ModelDraganddropFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDraganddrops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dname
        dtimer
        dquestion
        categories {
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlipcard = /* GraphQL */ `
  query GetFlipcard($id: ID!) {
    getFlipcard(id: $id) {
      id
      pfname
      ptimer
      pimage
      fwords {
        type
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFlipcards = /* GraphQL */ `
  query ListFlipcards(
    $filter: ModelFlipcardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlipcards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pfname
        ptimer
        pimage
        fwords {
          type
          text
          image
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMultioptionslist = /* GraphQL */ `
  query GetMultioptionslist($id: ID!) {
    getMultioptionslist(id: $id) {
      id
      mfname
      mtimer
      mquestion
      mimage
      mtest {
        id
        panswer
        pimage
      }
      manswer {
        checkans
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMultioptionslists = /* GraphQL */ `
  query ListMultioptionslists(
    $filter: ModelMultioptionslistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultioptionslists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mfname
        mtimer
        mquestion
        mimage
        mtest {
          id
          panswer
          pimage
        }
        manswer {
          checkans
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOptionsdrawlist = /* GraphQL */ `
  query GetOptionsdrawlist($id: ID!) {
    getOptionsdrawlist(id: $id) {
      id
      pfname
      ptimer
      pquestion
      drawques
      ptest {
        id
        panswer
      }
      oanswer
      createdAt
      updatedAt
    }
  }
`;
export const listOptionsdrawlists = /* GraphQL */ `
  query ListOptionsdrawlists(
    $filter: ModelOptionsdrawlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptionsdrawlists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pfname
        ptimer
        pquestion
        drawques
        ptest {
          id
          panswer
        }
        oanswer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVideooptionslist = /* GraphQL */ `
  query GetVideooptionslist($id: ID!) {
    getVideooptionslist(id: $id) {
      id
      videoid
      vfname
      vtimer
      vquestion
      vvideo {
        bucket
        key
        region
      }
      vsettime
      vtest {
        id
        panswer
      }
      vanswer
      createdAt
      updatedAt
    }
  }
`;
export const listVideooptionslists = /* GraphQL */ `
  query ListVideooptionslists(
    $filter: ModelVideooptionslistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideooptionslists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoid
        vfname
        vtimer
        vquestion
        vvideo {
          bucket
          key
          region
        }
        vsettime
        vtest {
          id
          panswer
        }
        vanswer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLeaderboard = /* GraphQL */ `
  query GetLeaderboard($id: ID!) {
    getLeaderboard(id: $id) {
      id
      name
      meetemail
      answerid
      score
      thisgamescore
      createdAt
      updatedAt
    }
  }
`;
export const listLeaderboards = /* GraphQL */ `
  query ListLeaderboards(
    $filter: ModelLeaderboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeaderboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        meetemail
        answerid
        score
        thisgamescore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEngagement = /* GraphQL */ `
  query GetEngagement($id: ID!) {
    getEngagement(id: $id) {
      id
      questionid
      engage
      people
      createdAt
      updatedAt
    }
  }
`;
export const listEngagements = /* GraphQL */ `
  query ListEngagements(
    $filter: ModelEngagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEngagements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionid
        engage
        people
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnswers = /* GraphQL */ `
  query GetAnswers($id: ID!) {
    getAnswers(id: $id) {
      id
      ffname
      fimage
      fquestion
      createdAt
      updatedAt
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ffname
        fimage
        fquestion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnswerslist = /* GraphQL */ `
  query GetAnswerslist($id: ID!) {
    getAnswerslist(id: $id) {
      id
      name
      email
      answerid
      answers
      aimgurl
      createdAt
      updatedAt
    }
  }
`;
export const listAnswerslists = /* GraphQL */ `
  query ListAnswerslists(
    $filter: ModelAnswerslistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswerslists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        answerid
        answers
        aimgurl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHightlightanswers = /* GraphQL */ `
  query GetHightlightanswers($id: ID!) {
    getHightlightanswers(id: $id) {
      id
      name
      thisanswer
      thgisaimgurl
      createdAt
      updatedAt
    }
  }
`;
export const listHightlightanswers = /* GraphQL */ `
  query ListHightlightanswers(
    $filter: ModelHightlightanswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHightlightanswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        thisanswer
        thgisaimgurl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSequencinggame = /* GraphQL */ `
  query GetSequencinggame($id: ID!) {
    getSequencinggame(id: $id) {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSequencinggames = /* GraphQL */ `
  query ListSequencinggames(
    $filter: ModelSequencinggameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSequencinggames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dname
        dtimer
        dquestion
        orders {
          id
          text
          image
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdobeexpress = /* GraphQL */ `
  query GetAdobeexpress($id: ID!) {
    getAdobeexpress(id: $id) {
      id
      dname
      videofile {
        bucket
        key
        region
      }
      adobeprojectid
      adobeimags {
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAdobeexpresses = /* GraphQL */ `
  query ListAdobeexpresses(
    $filter: ModelAdobeexpressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdobeexpresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dname
        videofile {
          bucket
          key
          region
        }
        adobeprojectid
        adobeimags {
          data
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRankingpoll = /* GraphQL */ `
  query GetRankingpoll($id: ID!) {
    getRankingpoll(id: $id) {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRankingpolls = /* GraphQL */ `
  query ListRankingpolls(
    $filter: ModelRankingpollFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRankingpolls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dname
        dtimer
        dquestion
        orders {
          id
          text
          image
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRankingresultlist = /* GraphQL */ `
  query GetRankingresultlist($id: ID!) {
    getRankingresultlist(id: $id) {
      id
      Rankingpoll
      rankingpoll {
        id
        vote
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRankingresultlists = /* GraphQL */ `
  query ListRankingresultlists(
    $filter: ModelRankingresultlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRankingresultlists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Rankingpoll
        rankingpoll {
          id
          vote
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
