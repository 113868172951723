import React, { useState} from "react";
import {
    Nav,
    NavLink
} from "./NavbarElements";
import logo from './Reachum.svg';
import "./css/Navbar.css"
const Navbarmobile = () => {
    return(
        <>
            <Nav>
                <NavLink to='/'>
                    <img src={logo} alt="Reachum" className="logos"/>
                </NavLink>
            </Nav>
        </>
    )
}

export default Navbarmobile;
