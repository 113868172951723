import React, {useEffect, useState} from "react";
import {
    useParams,
    useHistory
} from "react-router-dom";
import "./videorecordpager.css";
import {API, Auth, Storage} from "aws-amplify";
import {getPlayslide} from "../../graphql/queries";
import {Helmet} from "react-helmet";
import Navbars from "../Navbar";
import Goback from "../../img/dash/Go-Back-Button.png";
import {v4 as uuid} from "uuid";
import awsExports from "../../aws-exports";
import {updatePlayslide} from "../../graphql/mutations";
import {Button} from 'react-bootstrap';
import Foot from "../Foot";


let vbucket = awsExports.aws_user_files_s3_bucket;
let vregion = awsExports.aws_user_files_s3_bucket_region;
const Ideopreviewpager = (props) => {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [images, setImages] = useState([]);
    const [processing, setProcessing] = useState("");
    const history = useHistory();
    const { id } = useParams();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.username)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id},

            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    const  addImageTopDB = async (input) => {
        try {
            await API.graphql({
                query: updatePlayslide,
                variables: {input},

            });
        } catch (error) {
            console.log(error)
        }
    }
    const upload = async () => {
        const tl = uuid();
        localStorage.setItem("myreachumvideoquestionid",tl);
        const nfile = `videos/${tl}.webm`;
        const blob = props.location.state.videoBlob;
        await Storage.put(nfile, blob, {
            contentType: blob.type,
            progressCallback(progress) {
                const lods = progress.loaded;
                const done = progress.total;
                const loadin = Math.floor((lods / done) * 100);
                const per = loadin + "%";
                setProcessing(per)
                if (loadin === 100) {
                    setProcessing("Uploading...")
                }
            },
        }).then(() => {
            const ryrl = `https://v.reachum.com/vshowroom/${id}/${tl}`;
            const eryrl = `https://v.reachum.com/ideoedit/${id}/${tl}`;
            const vvv = {
                title: "record video",
                file: {
                    bucket: vbucket,
                    region: vregion,
                    key: nfile
                },
                rid: tl,
                rurl: ryrl,
                eurl: eryrl
            }
            const allImages = [...images, vvv]
            const input = {
                id,
                playlistlinks: allImages
            }
            addImageTopDB(input);
            setTimeout(() => {
                setProcessing("The file added to the content list.");
                history.push(`/editroom/${id}`);
            }, 1000)
        })
            .catch(err => console.log(err));
    }
    function gotorecord(){
        history.push(`/videorecord/${id}`);
    }
    function scp(){
        history.push(`/editroom/${id}`);
    }
    return (
        isOwner === isUser ?
                <>
                    <Helmet>
                        <title>Video Recording</title>
                    </Helmet>
                    <Navbars/>
                    <div className="uploadpptx">
                        <div className="upgradenew3">
                            <img src={Goback} className="dropbtn" onClick={scp} alt="go back"/>
                            <span className="tooltiptext3">Back to manage content</span>
                        </div>
                        <br/>
                        <br/>
                    <div className="videorecordepage">
                        <div>
                            {props.location.state && props.location.state.videoBlob && (
                                <div style={{width: "100%", maxWidth: 480, height: 360}}>
                                    <video
                                        src={window.URL.createObjectURL(props.location.state.videoBlob)}
                                        width={480}
                                        height={360}
                                        controls
                                        className="beauvideo"
                                    />
                                </div>
                            )}
                        </div>
                        <p>{processing}</p>
                        <div className="toprecord">
                            <Button onClick={gotorecord} className="mr-5">Re-record</Button>
                            <Button onClick={upload} className="mr-5">Use it</Button>
                        </div>
                    </div>
                    </div>
                    <Foot />
                </>
                : ""

    );
};

export default Ideopreviewpager

