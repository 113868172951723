/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://tgdohl5xmrehtjvuag4u77wtim.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-x6l3el6ewfasbljsgg5v3gwxje",
    "aws_cloud_logic_custom": [
        {
            "name": "vreachumv61whereby",
            "endpoint": "https://g9r74o7x6a.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "vinvitepeople",
            "endpoint": "https://7ea854v769.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:4597e820-e1ed-45ee-b787-2ad8bfbae119",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fWRR4xwDe",
    "aws_user_pools_web_client_id": "438tg6vkhsvssb72tadr1hqqno",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "vreachumv61145718-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "vreachumv61-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3kf70pu8ha2zr.cloudfront.net"
};


export default awsmobile;
