import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getFlipcard} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import "../../css/flip.css";
import Flipshow from "./flipshow";

function Flipshowroom() {
    const [isShow, setIsShow] = useState(true);
    const [counter, setCounter] = useState();
    const [cards, setCards] = useState([]);
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDas();
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getFlipcard,
                variables: {id:rid}
            })
            const datas = response.data.getFlipcard;
            const timers = datas.ptimer;
            setCounter(timers)
            const ques = datas.fwords;
            setCards(JSON.stringify(ques))
        }catch(err){
            console.log(err)
        }
    }
    function savethisdata(){
        localStorage.setItem("reachumflipcardgametimer",counter);
        localStorage.setItem("reachumflipcardgame",cards);
        setIsShow(false)
    }
    return (
        <>
            <div className="flipcardpage">
                    {isShow ?
                        <>
                            <br/>
                            <br/>
                            <br/>
                <h2>Memory matching game - Flipping card</h2>
                            <br/>
                <div>
                    <p className="biggertext">Select two cards with same meaning content<br/> consecutively to make them vanish.</p>
                </div>
                        <div className="startbutton">
                        <Button onClick={savethisdata} variant="success">CLICK TO START</Button>
                        </div>
                            </>
                        :
                        <>
                            <Flipshow />
                        </>
                    }
            </div>
        </>
    )
}

export default Flipshowroom;
