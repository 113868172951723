import React, {useEffect, useState} from 'react'
import {API, Auth} from "aws-amplify";
import {getPlayslide, getSequencinggame} from "../../../graphql/queries";
import { v4 as uuid } from 'uuid';
import {useHistory, useParams} from "react-router-dom";
import {updateSequencinggame} from "../../../graphql/mutations";
import {Button} from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from '../../../img/dash/Delete-Button-X.png';
import Moveup from "../../../img/reorder/scroll-up.png";
import Movedown from "../../../img/reorder/scroll-down.png";
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
          file,
          250,
          250,
          "JPEG",
          95,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
      );
    });
function move(items, item, index) {
  const before = items.slice(0, index)
  const beforeFiltered = before.filter(beforeItem => beforeItem.id !== item.id)
  const after = items.slice(index)
  const afterFiltered = after.filter(afterItem => afterItem.id !== item.id)
  const newItems = [...beforeFiltered, item, ...afterFiltered]
  return newItems
}

export default function Sequencingedit () {
  const { id, rid } = useParams();
  const history = useHistory();
  const [isUser, setIsUser] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [dname, setDname] = useState('')
  const [dtimer, setDtimer] = useState(0)
  const [dquestion, setDquestion] = useState('');
  const [uploadedmessage, setUploadedmessage] = useState("");
  const [showtimerform, setShowtimerform] = useState(false)
  useEffect( () => {
    Auth.currentAuthenticatedUser()
        .then(user => (setIsUser(user.username)))
        .catch(err => console.log(err));
    (async() => {
      const responses = await API.graphql({
        query: getPlayslide,
        variables:{id}
      })
      const datas = responses.data.getPlayslide;
      const iowner = datas.owner;
      setIsOwner(iowner);
    })()
  }, []);
  useEffect( () => {
    (async() => {
      const response = await API.graphql({
        query: getSequencinggame,
        variables:{id:rid}
      })
      const datas = response.data.getSequencinggame;
      const dn = datas.dname
      setDname(dn)
      const t = datas.dtimer
      if(t !== 0) {
        setDtimer(t)
        setShowtimerform(true)
      }
      const q = datas.dquestion
      setDquestion(q)
      const t1 = datas.orders;
      sessionStorage.setItem("thisorders",JSON.stringify(t1))
    })()
  }, []);
  const [items, setItems] = useState(() => {
    const stored = sessionStorage.getItem('thisorders')
    if (stored == null) {
      return [
        { id: uuid(), text: '', image: '' }
      ]
    }
    const parsed = JSON.parse(stored)
    return parsed
  })
  function changedtimer(event){
    setDtimer(event.target.value)
  }
  function changedquestion(event){
    setDquestion(event.target.value)
  }
  const itemViews = items.map((item, itemIndex) => {
    function handleDelete () {
      let text = "Are you sure you want to delete this item? \nEither OK or Cancel.";
      if (window.confirm(text) === true) {
        setItems(currentItems => {
          const newItems = currentItems.filter(currentItem => {
            return currentItem.id !== item.id
          })
          return newItems
        })
      }
    }
    function handleMoveUp() {
      setItems(currentItems => {
        const newIndex = Math.max(0, itemIndex - 1)
        return move(currentItems, item, newIndex)
      })
    }
    function handleMoveDown() {
      setItems(currentItems => {
        const newIndex = Math.min(currentItems.length, itemIndex + 2)
        return move(currentItems, item, newIndex)
      })
    }
    function handleItemChange(key, value) {
      setItems(currentItems => {
        const newItems = currentItems.map(currentItem => {
          const match = currentItem.id === item.id
          if (match) {
            return { ...currentItem, [key]: value }
          }
          return currentItem
        })
        return newItems
      })
    }
    function handleItemTextChange(event) {
      handleItemChange('text', event.target.value)
    }
    function handleItemImageChange(image) {
      handleItemChange('image', image)
    }
    async function handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        const image = await resizeFile(e.target.files[0]);
        handleItemImageChange(image)
      }
    }
    return (
        <li key={itemIndex} className="seborderbottom">
          <Grid container spacing={0} className="topspace">
            <Grid item xs={5} className="cccenter">
          <input value={item.text} onChange={handleItemTextChange} placeholder='Item text'  className="sefullwidth"/>
            </Grid>
            <Grid item xs={1} className="centerthis">
          <img src={Moveup} onClick={handleMoveUp} width="30px" />
            </Grid>
            <Grid item xs={1}>
          <img src={Movedown} onClick={handleMoveDown} width="30px" />
            </Grid>
            <Grid item xs={4}>
          <div>
            <span>Select Image </span>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            <img src={item.image} style={{ width: '120px' }}/>
          </div>
            </Grid>
            <Grid item xs={1} className="cccenter">
          <img src={DeleteIcon} onClick={handleDelete} width="30px"/>
            </Grid>
          </Grid>
        </li>
    )
  })
  function handleAdd() {
    setItems(currentItems => {
      const newItem = {
        id: uuid(),
        text: '',
        image: ''
      }
      return [...currentItems, newItem]
    })
  }
  async function handleSave() {
    try {
      const input = {
        id: rid,
        dtimer: dtimer,
        dquestion: dquestion,
        orders:items
      }
      await API.graphql({
        query: updateSequencinggame,
        variables: {input}
      });
      setUploadedmessage("Uploaded")
    } catch (err) {
      console.log(err)
    }
  }
  function scp(){
    history.push(`/editroom/${id}`);
  }
  return (
      isOwner === isUser ?
          <>
    <div style={{  width: '98%', padding: "10px"}}>
      <p className="redthis">If you don't see your content in the form, please close this window and open it again.</p>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
        Game Name: &nbsp;
        </Grid>
        <Grid item xs={10}>
         {dname}
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
          Timer : &nbsp;
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={0}>
            <Grid item xs={7} className="cccenter">
              <input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
              {showtimerform ?
                  <input
                      value={dtimer}
                      className="dragdroptimer"
                      type="number"
                      maxLength="3"
                      onChange={(e)=>setDtimer(e.target.value)}
                  />
                  : ""
              }
            </Grid>
            <Grid item xs={5} className="cccenter">
              <input type="radio" name="timer" value="NoTimer" onChange={()=>{
                setShowtimerform(false);
                setDtimer(0)
              }} /> No Timer
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
       Question: &nbsp;
        </Grid>
        <Grid item xs={10}>
    <textarea type="text" value={dquestion} onChange={changedquestion} className="dragdropques" maxLength="150" />
        </Grid>
      </Grid>
      <br/>
      <div>
        <h5>Put items in the correct sequence.</h5>
        <br/>
        <ol>{itemViews}</ol>
        <Button onClick={handleAdd} variant="secondary">Add item</Button>
      </div>
      <br/>
      <hr/>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={10} className="rightside">
          <p className="redthis">{uploadedmessage}</p>
        </Grid>
        <Grid item xs={2} className="centerthis">
      <button onClick={handleSave} className="answersubmit" >Update</button>
        </Grid>
      </Grid>
      <br/>
      <br/>
    </div>
          </>:""

  )
}
