import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Image} from "react-bootstrap";
import QRCode from 'qrcode'
import "./css/qrshow.css";
import {Helmet} from "react-helmet";
import html2PDF from "jspdf-html2canvas";
import {API} from "aws-amplify";
import {getPlayslide} from "../graphql/queries";

var opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    margin: 10,
    width:400
}

function Ipqrcodepage() {
    const [jvfile, setVjfile] = useState();
    const { id } = useParams();
    const [meetmeeting, setMeetmeeting] = useState("");
    QRCode.toDataURL(`https://v.reachum.com/iview/${id}`, opts)
        .then(url => {
            setVjfile(url)
        })
        .catch(err => {
            console.error(err)
        })
    useEffect( () => {
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;

            const images = datas.meetingname;
            if(images != null) {
                setMeetmeeting(images);
            }else{
                setMeetmeeting("Reachum self-Study");
            }
        })()
    }, []);
    function saveqrcode(){
        let pages = document.getElementById('thisicontent');
        html2PDF(pages, {
            jsPDF: {
                unit: "in",
                format: [8, 11]
            },
            output: `Reachum-${meetmeeting}-InPerson.pdf`
        });
    }
    return (
        <>
            <Helmet>
                <title>QR code</title>
            </Helmet>
            <div className="qrshows" >
                <button className="newclasslinksbutton" onClick={saveqrcode}>Download QR Code as PDF file</button>
                <div id="thisicontent" className="cccenter">
                    <br/>
                    <p>In-Person</p>
                    <Image src={jvfile} fluid className="imgviewsite"/>
                    <h2>{meetmeeting}</h2>
                </div>
            </div>
        </>
    );
}

export default Ipqrcodepage;
