import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getFasteranswer, listLeaderboards} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {Button} from "react-bootstrap";
import "../../css/poll.css";
import {createLeaderboard, updateLeaderboard, createEngagement} from "../../../graphql/mutations";
import Grid from "@material-ui/core/Grid";


function Fasteranswerroom() {
    const [isShows, setIsShows] = useState(true);
    const [quest, setQuest] = useState();
    const [messages, setMessages] = useState();
    const [ans, setAns] = useState([]);
    const [vals, setVals] = useState("");
    const { id, rid } = useParams();
    const [counter, setCounter] = useState();
    const [isopenimg, setIsopenimg] = useState(false);
    const [thisimg, setThisimg] =useState("");
    const [playpause, setPlaypause] = useState(true);
    const [timeval, setTimeval] = useState(0);
    const [thissaved, setThissaved] = useState([]);
    const [submittedmessage, setSubmittedmessage] = useState("");
    const [isDisabled, setDisabled] = useState(false);
    const [isShowcorrects, setIsShowcorrects] = useState(true)
    const [thiscorrect, setThiscorrect] = useState("");
    const [showclock, setShowclock] = useState(false);
    useEffect( () => {
        fetchDas();
        const submitrids = sessionStorage.getItem("submitrid");
        if(submitrids){
            setThissaved(submitrids)
            if(submitrids.includes(rid)){
                setDisabled(true);
                setSubmittedmessage("Submitted")
            }
        }
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getFasteranswer,
                variables: {id:rid}
            })
            const datas = response.data.getFasteranswer;
            const timers = datas.ftimer;
            if(timers !== 0) {
                setCounter(timers);
                setShowclock(true);
            }else{
                setTimeval(40)
            }
            const ques = datas.fquestion;
            setQuest(ques);
            const tests = datas.fanswer.toLowerCase();
            setAns(tests);
            const ttr = datas.fanswer;
            setThiscorrect(ttr)
            const thisimage = datas.fimage;
            if(thisimage){
                setThisimg(thisimage);
                setIsopenimg(true)
            }
        }catch(err){
            console.log(err)
        }
    }
    const handleChanges = e => {
        const thisval = e.target.value.toLowerCase()
            setVals(thisval);
    }
    const psubmit = async () => {
        let tt = [];
        if (thissaved){
            tt = [thissaved, rid]
        }else{
            tt = [rid]
        }
        sessionStorage.setItem("submitrid", tt);
        setPlaypause(false);
           let mymeetnames = localStorage.getItem("vreachumnewername");
          let mymeetemails = localStorage.getItem("vreachumneweremail");
        const pattern = new RegExp(ans);
        if (pattern.test(vals)) {
                    try {
                        API.graphql({
                            query: listLeaderboards,
                            variables: {
                                filter: {
                                    "meetemail": {
                                        eq: mymeetemails
                                    },
                                    "answerid":{
                                        eq: id
                                    }
                                },
                                limit:10000
                            }
                        }).then(datas => {
                            const plays = datas.data.listLeaderboards.items;
                            const playsl = plays.length;
                            if(playsl > 0) {
                                const myscore = plays[0].score;
                                const myid = plays[0].id;
                                const myscores = timeval + myscore;
                                try {
                                    const input = {
                                        id: myid,
                                        score: myscores,
                                        thisgamescore:timeval
                                    }
                                    API.graphql({
                                        query: updateLeaderboard,
                                        variables: {input}
                                    });
                                } catch (err) {
                                    console.log(err)
                                }
                            }else{
                                try {
                                    const input = {
                                        answerid: id,
                                        name: mymeetnames,
                                        meetemail:mymeetemails,
                                        score: timeval,
                                        thisgamescore:timeval
                                    }
                                    API.graphql({
                                        query: createLeaderboard,
                                        variables: {input}
                                    });
                                } catch (err) {
                                    console.log(err)
                                }
                            }
                            setMessages(`You scored ${timeval} points.`)
                        })
                    } catch (err) {
                        console.log(err)
                    }
                }
             else {
                    try {
                        API.graphql({
                            query: listLeaderboards,
                            variables: {
                                filter: {
                                    "meetemail": {
                                        eq: mymeetemails
                                    },
                                    "answerid":{
                                        eq: id
                                    }
                                },
                                limit:10000
                            }
                        }).then(datas => {
                            const plays = datas.data.listLeaderboards.items;
                            const playsl = plays.length;
                            if (playsl === 0) {
                                try {
                                    const input = {
                                        answerid: id,
                                        name: mymeetnames,
                                        meetemail:mymeetemails,
                                        score: 0,
                                        thisgamescore:0
                                    }
                                    API.graphql({
                                        query: createLeaderboard,
                                        variables: {input}
                                    });

                                } catch (err) {
                                    console.log(err)
                                }
                            }else{
                                const myid = plays[0].id;
                                try {
                                    const input = {
                                        id: myid,
                                        thisgamescore:0
                                    }
                                    API.graphql({
                                        query: updateLeaderboard,
                                        variables: {input}
                                    });
                                } catch (err) {
                                    console.log(err)
                                }
                            }
                        })
                    } catch (err) {
                        console.log(err)
                    }
                setMessages(`You scored 0 points.`)
            }
       setIsShows(false);
        try {
            const input = {
                questionid: id,
                engage: 1,
                people: 1
            }
            API.graphql({
                query: createEngagement,
                variables: {input}
            });
        } catch (err) {
            console.log(err)
        }
    };

    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setTimeval(0)
            return <div className="timer">0</div>;
        }else{
            setTimeval(remainingTime)
        }
        return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
        );
    }

    const getdatas = async () =>{
        const mymeetnams = localStorage.getItem("vreachumnewername");
        const mymeetemals = localStorage.getItem("vreachumneweremail");
        if(mymeetemals) {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "meetemail": {
                                eq: mymeetemals
                            },
                            "answerid": {
                                eq: id
                            }
                        },
                        limit: 10000
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl === 0) {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnams,
                                meetemail: mymeetemals,
                                score: 0,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }else{
                        const myid = plays[0].id;
                        try {
                            const input = {
                                id: myid,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                        setMessages("You scored 0 points.")
                        setIsShows(false);
                        try {
                            const input = {
                                questionid: id,
                                engage: 0,
                                people: 1
                            }
                            API.graphql({
                                query: createEngagement,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                })
            } catch (err) {
                console.log(err)
            }
            setIsShowcorrects(false)
            setIsShows(false);
        }else {
            setMessages("Time is up")
            setIsShows(false);
            setIsShowcorrects(false)
        }
    }
    return (
        <div className="fastanswerspage2">
                     <div className="greenback">
                         <Grid container spacing={0}>
                             <Grid item xs={2}>
                                 {showclock ?
                                     <div className="lpollheadclock2">
                                         <CountdownCircleTimer
                                             isPlaying={playpause}
                                             duration={counter}
                                             size={50}
                                             colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                             colorsTime={[7, 5, 2, 0]}
                                             onComplete={getdatas}
                                         >
                                             {renderTime}
                                         </CountdownCircleTimer>
                                     </div>
                                     : ""
                                 }
                             </Grid>
                             <Grid item xs={10}>
                            <div className="fastanswes">
                        <h2>{quest}</h2>
                            </div>
                             </Grid>
                         </Grid>
                     </div>
                        <div className="centerside">
                            <br/>
                            {isopenimg ?
                                <img src={thisimg} className="fillinthimgshow"/>
                                : ""
                            }
                        </div>
                         {isShows ?
                             <>
                                 <div>
                                     <br/>
                                     <br/>
                                     <br/>
                                     <br/>
                                     <p className="biggertext">Your answer:</p>
                                     <input type="text" onChange={handleChanges} maxlength="100" name="fanswer" className="fullline"
                                            placeholder="Type here"/>
                                     <br/>
                                 </div>
                                 <br/>
                                 <div className="fastbottom">
                                     <div className="cccenter">
                                         <p className="redthis">{submittedmessage}</p>
                                             <Button id="pollsubmit" onClick={psubmit}
                                                     className="inputpolllist" disabled={isDisabled}>Submit</Button>
                                     </div>
                                 </div>
                             </>
                             :
                             <>
                                 {isShowcorrects ?
                                     <div className="cccenter">
                                         <br/>
                                         <p className="biggertext"> Your answer is <span className="redthis">{vals}</span></p>
                                         <p className="biggertext"> The correct answer is <span className="redthis">{thiscorrect}</span></p>
                                     </div>
                                     : ""
                                 }
                             <div className="fastanswers">
                                 <p className="pollmaeeage">{messages}</p>
                                 <br/>
                                 <br/>
                                 <br/>
                             </div>
                                 </>
                         }
            </div>
    )
}

export default Fasteranswerroom;

