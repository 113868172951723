import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../graphql/mutations';
import { v4 as uuid } from 'uuid';
import "../css/uploadurl.css";
import {getPlayslide} from "../../graphql/queries";
import Grid from "@material-ui/core/Grid";

function Uploadvimeo({id, handleClose75}) {
    const [val, setVal] = useState("");
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [images, setImages] = useState([]);
    const [smessage, setSmessage] = useState("")
    const [opensmessage, setOpensmessage] = useState(true)
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.username)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    const handleChange = e => {
        setVal(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const rid = uuid();
            const rurl = `https://v.reachum.com/vimeoshowroom/${id}/${rid}`;
            const erurl = `https://v.reachum.com/vimeoedit/${id}/${rid}`
            const urldata = {
                rid,
                title:"embed video",
                lurl:val,
                rurl,
                eurl: erurl
            };
            const allImages = [...images, urldata]
            const input = {
                id,
                playlistlinks: allImages
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
            setOpensmessage(false);
            setSmessage("Submitted");
            setTimeout(() => {
                handleClose75()
            }, 1000)
        }catch(err){
            console.log(err)
        }
    }
    return (
        isOwner === isUser ?
        <>
        <div className="uploadurl">
            <div className="cccenter">
                <p className="biggertext">
                    Add a Youtube or Vimeo video <br/> and play/pause controlled by the viewer
                </p>
            </div>
                    <br/>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                                <input onChange={handleChange} className="form-control" name="clink" placeholder="URL"/>
                                </Grid>
                                <Grid item xs={3}>
                                    {opensmessage ?
                                        <input type="submit" value="Submit" className="answersubmit"/>
                                        :
                                        <p className="redthis"> {smessage} </p>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </form>
            </div>
            </>
            :""
    );
}

export default Uploadvimeo;
