import React from "react";
import {
    Authenticator,
    useAuthenticator,
    useTheme,
    Button,
    View
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import "./css/login.css";
import {useHistory} from "react-router-dom";


const Login = () => {
    let history = useHistory();
    const components = {
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" paddingTop={tokens.space.large}>
                    <h3> Log in </h3>
                </View>
            );
        },
        SignIn: {
            Footer() {
                const { toResetPassword } = useAuthenticator();
                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toResetPassword}
                            size="small"
                            variation="link"
                        >
                            Forgot your password?
                        </Button>
                        <br/>
                        <br/>
                    </View>
                );
            },
        }
    };
    return (
        <>
            <br/>
            <Authenticator components={components} hideSignUp={true}>
            </Authenticator>
        </>
    )
}

export default Login
