import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getOptionsdrawlist, listLeaderboards} from '../../../graphql/queries';
import { useParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import "../../css/optionshowroom.css";
import {createEngagement, createLeaderboard, updateLeaderboard} from "../../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Thiswrong from "../../../img/view/close.png";
import Thiscorrect from "../../../img/view/check-mark.png";


function Drawoptionshowroom() {
    const [isShow, setIsShow] = useState(true);
    const [quest, setQuest] = useState();
    const [pchecked, setPchecked] = useState();
    const [messages, setMessages] = useState();
    const [isShows, setIsShows] = useState(true);
    const [clist, setClist] = useState([]);
    const [playpause, setPlaypause] = useState(true);
    const [timeval, setTimeval] = useState(0);
    const { id, rid } = useParams();
    const [counter, setCounter] = useState();
    const [ans, setAns] = useState([]);
    const [cans, setCans] = useState([]);
    const [imgs, setImgs] = useState("");
    const [thissaved, setThissaved] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [isShowcorrects, setIsShowcorrects] = useState(true);
    const [submittedmessage, setSubmittedmessage] = useState("");
    const [answerthis, setAnswerthis] = useState(0);
    const [showclock, setShowclock] = useState(false);
    useEffect(() => {
        fetchDas();
        const submitrids = sessionStorage.getItem("submitrid");
        if(submitrids){
            setThissaved(submitrids)
            if(submitrids.includes(rid)){
                setDisabled(true);
                setSubmittedmessage("Submitted")
            }
        }
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getOptionsdrawlist,
                variables: {id:rid}
            })
            const datas = response.data.getOptionsdrawlist;
            const timers = datas.ptimer;
            if(timers !== 0) {
                setCounter(timers);
                setShowclock(true);
            }else{
                setTimeval(40)
            }
            const ques = datas.pquestion;
            setQuest(ques);
            const tests = datas.ptest;
            setClist(tests);
            const ct = datas.oanswer;
            setAns(ct);
            const canss = tests[ct].panswer;
            setCans(canss);
            const ma = datas.drawques;
            setImgs(ma);

        }catch(err){
            console.log(err)
        }
    }
    const psubmit = async () => {
        let tt = [];
        if (thissaved){
            tt = [thissaved, rid]
        }else{
            tt = [rid]
        }
        sessionStorage.setItem("submitrid", tt);
        setPlaypause(false);
        let mymeetnames = localStorage.getItem("vreachumnewername");
        let mymeetemails = localStorage.getItem("vreachumneweremail");
        if (pchecked === ans) {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "meetemail": {
                                eq: mymeetemails
                            },
                            "answerid": {
                                eq: id
                            }
                        }
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl > 0) {
                        const myscore = plays[0].score;
                        const myid = plays[0].id;
                        const myscores = timeval + myscore;
                        try {
                            const input = {
                                id: myid,
                                score: myscores,
                                thisgamescore:timeval
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    } else {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnames,
                                meetemail:mymeetemails,
                                score: timeval,
                                thisgamescore:timeval
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                    setMessages(`You scored ${timeval} points.`)
                })
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "meetemail": {
                                eq: mymeetemails
                            },
                            "answerid": {
                                eq: id
                            }
                        }
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl === 0) {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnames,
                                meetemail:mymeetemails,
                                score: 0,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });

                        } catch (err) {
                            console.log(err)
                        }
                    }else{
                        const myid = plays[0].id;
                        try {
                            const input = {
                                id: myid,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                })
            } catch (err) {
                console.log(err)
            }
            setMessages(`You scored 0 points.`)
        }
        setIsShows(false);
        setIsShow(false);
        try {
            const input = {
                questionid: id,
                engage: 1,
                people: 1
            }
            API.graphql({
                query: createEngagement,
                variables: {input}
            });
        } catch (err) {
            console.log(err)
        }
        const ttn = Number(pchecked);
        setAnswerthis(ttn)
    };
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setTimeval(0)
            return <div className="timer">0</div>;
        }else{
            setTimeval(remainingTime)
        }
        return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
        );
    }

    const getdatas = async () =>{
        const mymeetnams = localStorage.getItem("vreachumnewername");
        const mymeetemals = localStorage.getItem("vreachumneweremail");
        if(mymeetemals) {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "meetemail": {
                                eq: mymeetemals
                            },
                            "answerid": {
                                eq: id
                            }
                        },
                        limit:10000
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl === 0) {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnams,
                                meetemail: mymeetemals,
                                score: 0,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }else{
                        const myid = plays[0].id;
                        try {
                            const input = {
                                id: myid,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                    setMessages("You scored 0 points.")
                    setIsShows(false);
                    setIsShow(false);
                    try {
                        const input = {
                            questionid: id,
                            engage: 0,
                            people: 1
                        }
                        API.graphql({
                            query: createEngagement,
                            variables: {input}
                        });
                    } catch (err) {
                        console.log(err)
                    }
                })
            } catch (err) {
                console.log(err)
            }
            setIsShowcorrects(false)
            setIsShows(false);
            setIsShow(false);
        }else{
            setMessages("Time is up.")
            setIsShows(false);
            setIsShow(false);
            setIsShowcorrects(false)
        }
    }

    return (
        <>
            <div className="drawoptionpage2">
                    <div className="greenback">
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                {showclock ?
                                    <div className="lpollheadclock2">
                                        <CountdownCircleTimer
                                            isPlaying={playpause}
                                            duration={counter}
                                            size={50}
                                            colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                            colorsTime={[7, 5, 2, 0]}
                                            onComplete={getdatas}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                    : ""
                                }
                            </Grid>
                            <Grid item xs={10}>
                                <div className="fastanswes">
                                    <h2>{quest}</h2>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="centerside">
                        <img src={imgs} className="drawwidth"/>
                    </div>
                    <div className="drawoptioninsidepage">
                        {isShows ?
                            <>
                                    <div className="oneoptionlisttop">
                                        {
                                            clist.map((item, index) => {
                                                return (
                                                    <>
                                                    <lable key={item.id} className="oneoptionlist">
                                                        <input
                                                            type="radio"
                                                            defaultValue={item.panswer}
                                                            name="panswer"
                                                            onChange={() => {
                                                                setPchecked(item.id)
                                                            }}
                                                            id={item.id}
                                                            className="biggerradio"
                                                        />
                                                        {item.panswer}
                                                    </lable>
                                                        <br/>
                                                    </>
                                                    )
                                                }
                                            )
                                        }
                                    <br/>
                                    <Grid container spacing={0} className="pollbottoms">
                                        <Grid item xs={7}>
                                        </Grid>
                                        <Grid item xs={5} className="centerside">
                                            <p className="redthis">{submittedmessage}</p>
                                            <Button id="pollsubmit" onClick={psubmit}
                                                    className="inputpolllist" disabled={isDisabled}>Submit</Button>
                                        </Grid>
                                    </Grid>
                                    </div>
                            </> :
                            <>
                                {isShowcorrects ?
                                    <div className="oneoptionlisttop">
                                        <ul className="nonheaderul">
                                            {
                                                clist.map((item, index) => {
                                                        const convertedToNumbers = Number(ans);
                                                        let shouldBeChecked = null;
                                                        if (convertedToNumbers === index) {
                                                            shouldBeChecked = `${Thiscorrect}`;
                                                        }
                                                        let isChecked = null;
                                                        if (answerthis === index) {
                                                            isChecked = `${Thiswrong}`;
                                                        }
                                                        const icon = shouldBeChecked || isChecked || '';

                                                        return (
                                                            <>
                                                                <li key={index} className="oneoptionlist">
                                                                    <img className="correctandincorrect" src={icon}
                                                                         width="20px"/>
                                                                    {item.panswer}
                                                                </li>
                                                                <br/>
                                                            </>
                                                        )
                                                    }
                                                )
                                            }
                                        </ul>
                                    </div>
                                    : ""
                                }
                                <div className="fastanswers">
                                <p className="pollmaeeage">{messages}</p>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                            </>
                        }
                    </div>
            </div>
            </>
            )
}

export default Drawoptionshowroom;
