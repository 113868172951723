import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getMultioptionslist, listLeaderboards} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import { useForm } from 'react-hook-form';
import "../../css/optionshowroom.css";
import "../../css/multioptions.css";
import {createLeaderboard, updateLeaderboard, createEngagement} from "../../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Thiswrong from "../../../img/view/close.png";
import Thiscorrect from "../../../img/view/check-mark.png";

function Multioptionshowroom() {
    const { register, handleSubmit, errors } = useForm();
    const [quest, setQuest] = useState();
    const [messages, setMessages] = useState();
    const [isShows, setIsShows] = useState(true);
    const [clist, setClist] = useState([]);
    const [playpause, setPlaypause] = useState(true);
    const [timeval, setTimeval] = useState(0);
    const { id, rid } = useParams();
    const [counter, setCounter] = useState();
    const [ans, setAns] = useState([]);
    const [cans, setCans] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [isShowcorrects, setIsShowcorrects] = useState(true);
    const [answersthis, setAnswersthis] = useState([]);
    const [submittedmessage, setSubmittedmessage] = useState("");
    const [thissaved, setThissaved] = useState([]);
    const [showclock, setShowclock] = useState(false);
    useEffect( () => {
        fetchDas();
        const submitrids = sessionStorage.getItem("submitrid");
        if(submitrids){
            setThissaved(submitrids)
            if(submitrids.includes(rid)){
                setDisabled(true);
                setSubmittedmessage("Submitted")
            }
        }
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getMultioptionslist,
                variables: {id:rid}
            })
            const datas = response.data.getMultioptionslist;
            const timers = datas.mtimer;
            if(timers !== 0) {
                setCounter(timers);
                setShowclock(true);
            }else{
                setTimeval(40)
            }
            const ques = datas.mquestion;
            setQuest(ques);
            const tests = datas.mtest;
            setClist(tests);
            const ct = datas.manswer;
            let cts =[];
            let text = "";
            for (let i = 0; i < ct.length; i++) {
                const t = ct[i].checkans;
                cts.push(t);
                text += tests[Number(t)].panswer + " . "
            }
            setAns(cts);
            setCans(text)

        }catch(err){
            console.log(err)
        }
    }
    const isArrayIncluded = (array1, array2) => {
        return array1.every(item => array2.includes(item));
    }
    const onSubmit = async (data) => {
        let tt = [];
        if (thissaved){
            tt = [thissaved, rid]
        }else{
            tt = [rid]
        }
        sessionStorage.setItem("submitrid", tt);
        setPlaypause(false);
            let mymeetnames = localStorage.getItem("vreachumnewername");
            let mymeetemails = localStorage.getItem("vreachumneweremail");
        const thisdata = data.panswer;
        const isIncluded = isArrayIncluded(thisdata, ans);
        const thisansl = ans.length;
        let countMatchingValues;
        if(isIncluded === true){
            const rr = thisdata.length;
            const tr = timeval * rr;
            const cm = tr/thisansl;
            countMatchingValues = Math.floor(cm);
        }else{
            countMatchingValues = 0
        }
            if (countMatchingValues > 0) {
                try {
                    API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: mymeetemails
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit:10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playsl = plays.length;
                        if (playsl > 0) {
                            const myscore = plays[0].score;
                            const myid = plays[0].id;
                            const myscores = countMatchingValues + myscore;
                            try {
                                const input = {
                                    id: myid,
                                    score: myscores,
                                    thisgamescore: countMatchingValues
                                }
                                API.graphql({
                                    query: updateLeaderboard,
                                    variables: {input}
                                });
                            } catch (err) {
                                console.log(err)
                            }
                        } else {
                            try {
                                const input = {
                                    answerid: id,
                                    name: mymeetnames,
                                    meetemail:mymeetemails,
                                    score: countMatchingValues,
                                    thisgamescore: countMatchingValues
                                }
                                API.graphql({
                                    query: createLeaderboard,
                                    variables: {input}
                                });
                            } catch (err) {
                                console.log(err)
                            }
                        }
                        setMessages(`You scored ${countMatchingValues} points.`)
                    })
                } catch (err) {
                    console.log(err)
                }
            } else {
                try {
                    API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: mymeetemails
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit:10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playsl = plays.length;
                        if (playsl === 0) {
                            try {
                                const input = {
                                    answerid: id,
                                    name: mymeetnames,
                                    meetemail:mymeetemails,
                                    score: 0,
                                    thisgamescore: 0
                                }
                                API.graphql({
                                    query: createLeaderboard,
                                    variables: {input}
                                });

                            } catch (err) {
                                console.log(err)
                            }
                        }else{
                            const myid = plays[0].id;
                            try {
                                const input = {
                                    id: myid,
                                    thisgamescore:0
                                }
                                API.graphql({
                                    query: updateLeaderboard,
                                    variables: {input}
                                });
                            } catch (err) {
                                console.log(err)
                            }
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
                setMessages(`You scored 0 points.`)
            }
        setIsShows(false);
        try {
            const input = {
                questionid: id,
                engage: 1,
                people: 1
            }
            API.graphql({
                query: createEngagement,
                variables: {input}
            });
        } catch (err) {
            console.log(err)
        }
        const convertedToNumbers = thisdata.map(Number)
        setAnswersthis(convertedToNumbers)
    };
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setTimeval(0)
            return <div className="timer">0</div>;
        }else{
            setTimeval(remainingTime)
        }
        return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
        );
    }
    const getdatas = async () =>{
        const mymeetnams = localStorage.getItem("vreachumnewername");
        const mymeetemals = localStorage.getItem("vreachumneweremail");
        if(mymeetemals) {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "meetemail": {
                                eq: mymeetemals
                            },
                            "answerid": {
                                eq: id
                            }
                        },
                        limit: 10000
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl === 0) {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnams,
                                meetemail: mymeetemals,
                                score: 0,
                                thisgamescore: 0
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }else{
                        const myid = plays[0].id;
                        try {
                            const input = {
                                id: myid,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                        setMessages(`You scored 0 points.`);
                        setIsShows(false);
                        try {
                            const input = {
                                questionid: id,
                                engage: 0,
                                people: 1
                            }
                            API.graphql({
                                query: createEngagement,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                })
            } catch (err) {
                console.log(err)
            }
            setIsShowcorrects(false)
            setIsShows(false);
        }else{
            setMessages("Time is Up")
            setIsShows(false);
            setIsShowcorrects(false)
        }
    }
    return (
        <>
            <div className="drawoptionpage2">
                        <div className="greenback">
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    {showclock ?
                                        <div className="lpollheadclock2">
                                            <CountdownCircleTimer
                                                isPlaying={playpause}
                                                duration={counter}
                                                size={50}
                                                colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                                colorsTime={[7, 5, 2, 0]}
                                                onComplete={getdatas}
                                            >
                                                {renderTime}
                                            </CountdownCircleTimer>
                                        </div>
                                        : ""
                                    }
                                </Grid>
                                <Grid item xs={10}>
                                    <div className="fastanswes">
                                        <h2>{quest}</h2>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <br/>
                        <div className="drawoptioninsidepage">
                        {isShows ?
                            <>
                                <div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="reachumcheckbox-container">
                                            {
                                                clist.map((item, index) => {
                                                        return (
                                                            <>
                                                                <label key={index} className="reachumcheckbox-label">
                                                                    <input
                                                                        type="checkbox"
                                                                        value={item.id}
                                                                        name="panswer"
                                                                        ref={register({required: 'Please select'})}
                                                                        style={{
                                                                            marginRight: "15px",
                                                                            transform: "scale(2)",
                                                                            cursor: "pointer"
                                                                        }}
                                                                    />
                                                                    {item.panswer}
                                                                </label>
                                                            </>
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                    <br/>
                                    <br/>
                                    <Grid container spacing={0} className="pollbottoms">
                                        <Grid item xs={7}>
                                        </Grid>
                                        <Grid item xs={5} className="centerside">
                                            <p className="redthis">{submittedmessage}</p>
                                            <input type="submit" className="inputpolllist" disabled={isDisabled} value="Submit" />
                                        </Grid>
                                    </Grid>
                                </form>
                                </div>
                            </> :
                            <>
                                {isShowcorrects ?
                                    <div className="reachumcheckbox-container">
                                        <ul className="nonheaderul">
                                            {
                                                clist.map((item, index) => {
                                                        const convertedTo = ans.map(Number)
                                                        const shouldBeChecked = convertedTo.includes(index)
                                                        const isChecked = answersthis.includes(index)
                                                        const icon = shouldBeChecked
                                                            ? `${Thiscorrect}`
                                                            : isChecked
                                                                ? `${Thiswrong}`
                                                                : null
                                                        return (
                                                            <>
                                                                <li key={index} className="reachumcheckbox-label">
                                                                    <img className="correctandincorrect" src={icon}
                                                                         width="20px"/>
                                                                    {item.panswer}
                                                                </li>
                                                            </>
                                                        )
                                                    }
                                                )
                                            }
                                        </ul>
                                    </div>
                                    : ""
                                }
                                <div className="fastanswers">
                                <p className="pollmaeeage">{messages}</p>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                            </>
                        }
                        </div>
            </div>
            </>
            )
}

export default Multioptionshowroom;
