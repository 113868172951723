import React, {useState, useMemo, useEffect, useRef} from 'react';
import {API} from "aws-amplify";
import {createRankingresultlist} from "../../graphql/mutations";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../css/taptap.css";
import {listRankingresultlists} from "../../graphql/queries";
import Chart from 'chart.js/auto';

function shuffle(items) {
  const entries = items.map((item) => [Math.random(), item])
  entries.sort((a, b) => a[0] - b[0])
  return entries.map((entry) => entry[1])
}

export default function Showitemlist() {
  const [quest, setQuest] = useState();
  const [timeval, setTimeval] = useState(0);
  const { id, rid } = useParams();
  const [messages, setMessages] = useState();
  const [isopen, setIsopen] = useState(true);
  const [isshowreset, setIsshowreset] = useState(false);
  const [counter, setCounter] = useState();
    const [voter, setVoter] = useState();
  const [rankingresultslists, setRankingresultslists] = useState([]);
  useEffect(() => {
    const newCards=sessionStorage.getItem("rankinggamesques");
    setQuest(newCards);
    const newcounter=Number(sessionStorage.getItem("rankinggamescounter"));
    setCounter(newcounter);
  }, []);
  const storedItems = useMemo(() => {
    const stored = sessionStorage.getItem('rankinggames');
    if (stored == null) {
      return []
    }
    return JSON.parse(stored)
  }, [])
  const [items, setItems] = useState(() => {
    return shuffle(storedItems)
  })
  const defaultAnswers = items.map(() => {
    return {
      id: '',
      text: '',
      image: ''
    }
  })

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setTimeval(0)
      return <div className="timer">0</div>;
    }else{
      setTimeval(remainingTime)
    }
    return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
    );
  }
  const [activeItem, setActiveItem] = useState()

  const [answers, setAnswers] = useState(() => {
    return defaultAnswers
  })
  const answerViews = answers.map((answer, answerIndex) => {
    function handleClick () {
      if (activeItem == null) {
        return
      }
      setAnswers(currentAnswers => {
        const newAnswers = currentAnswers.map((currentAnswer, currentAnswerIndex) => {
          if (answerIndex === currentAnswerIndex) {
            return activeItem
          }
          return currentAnswer
        })
        return newAnswers
      })
      setActiveItem(undefined)
    }
    const key = answer.id !== '' ? answer.id : answerIndex
    const image = answer.image !== '' && (
        <img src={answer.image} style={{ width: '170px' }} />
    )
    return (
        <>
        <li key={key} onClick={handleClick} style={{fontSize: "20px", marginLeft:"8px", marginBottom:"8px", backgroundColor:"#f0f0f0", color:"#0c4128", padding:"5px"}}>
         <div className="centerthis">
          <span style={{marginLeft:"8px"}}>{answer.text}</span> &nbsp; &nbsp;
          {image}
         </div>
        </li>
          </>
    )
  })
  const remainingItems = items.filter(item => {
    const answered = answers.some(answer => answer.id === item.id)
    return !answered
  })
  const remainingViews = remainingItems.map(item => {
    function handleClick () {
      setActiveItem(item);
      setIsshowreset(true)
    }
    const isActive = activeItem != null && activeItem.id === item.id;
    const style = { margin: '5px', padding: '10px', fontSize:"20px", border:"1px solid #d2196e",borderRadius:"10px" }
    if (isActive) {
      style.background='#e82c82';
      style.color="#ffffff";
      style.borderRadius="10px";
      style.transform="scale(1.5, 1.5)"
    }
    const image = item.image !== '' && (
        <img src={item.image} style={{ width: '170px' }} />
    )
    return (
        <div
            key={item.id}
            style={style}
            onClick={handleClick}
        >
          <span>{item.text}</span><br/>
          {image}
        </div>
    )
  })
  function handleReset () {
    setAnswers(defaultAnswers);
    setIsshowreset(false)
  }

 const handleSubmit = async () => {
     setIsopen(false);
    const thisarray=[];
   answers.forEach((answer, index) => {
     const rankings = answers.length - index;
     const tt = answer.id;
     thisarray.push({id: tt, vote : rankings})
   })
  try {
     const input = {
       Rankingpoll:rid,
       rankingpoll: thisarray
     }
     await API.graphql({
       query: createRankingresultlist,
       variables: {input}
     });
     if(counter > 0) {
       setMessages("Group results shown below when time runs out. Scroll down to view.")
     }
     document.getElementById("pollsubmit").setAttribute("disabled", "true");
     document.getElementById("pollsubmit").style.backgroundColor = "#E24761";
   }catch (err) {
     console.log(err)
   }
  }
  const getdatas = async () =>{
      setIsopen(false);
      try{
          const response = await API.graphql({
              query: listRankingresultlists,
              variables: {
                  filter: {
                      "Rankingpoll": {
                          eq: rid
                      }
                  },
                  limit:10000
              }
          })
          const results = response.data.listRankingresultlists.items;
          const clength = results.length;
          setVoter(clength)
          let array=[];
          for (let j = 0; j < clength; j++) {
              const parray = results[j].rankingpoll;
              array.push(parray)
          }
          const aggregatedItems = {};
          for (const subArray of array) {
              for (const item of subArray) {
                  const { id, vote } = item;
                  if (!aggregatedItems[id]) {
                      aggregatedItems[id] = [vote];
                  } else {
                      aggregatedItems[id].push(vote);
                  }
              }
          }
          const items = Object.entries(aggregatedItems).map(([id, vote]) => ({
              id,
              vote
          }));
          const averages = {}
          items.forEach((answer, index) => {
             const tt = answer.vote;
              const total = tt.reduce((total, ranking) => total + ranking, 0)
              const average = Number((total / tt.length).toFixed(2));
              averages[answer.id] = average
          })
          const stored = sessionStorage.getItem('rankinggames');
          const gg = JSON.parse(stored);
          const matchedData = gg.map(item => ({
              id: item.id,
              text:item.text,
              value: averages[item.id] || null, // Get the corresponding value from array2 or null if not found
          }));
          setRankingresultslists(matchedData)
          setMessages("");
         document.getElementById("pollsubmit").setAttribute("disabled", "true");
         document.getElementById("pollsubmit").style.backgroundColor = "#E24761";
          setTimeout(()=> {
             // setIsOpen(false);
              document.getElementById("pollsubmit").style.display = "none";
          },600)
          setTimeout(function() {getdatas()}, 5000);
      }catch (err) {
          console.log(err)
      }
  }
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
    }
    if (rankingresultslists.length > 0) {
        const ctx = chartRef.current.getContext('2d');
            const labels = rankingresultslists.map(item => item.text);
            const values = rankingresultslists.map(item => item.value);
        const newChartInstance = new Chart(ctx, {
            type: 'bar',
            data: {
                labels,
                datasets: [
                    {
                        label: 'Results',
                        data: values,
                        backgroundColor: '#72b5a4', // Bar color
                        borderColor: '#269b7b', // Border color
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: `${voter} votes`,
                    },
                    legend: {
                        display: true,
                        position: 'top', // Options: 'top', 'bottom', 'left', 'right'
                    },
                    tooltip: {
                        enabled: true,
                        mode: 'nearest', // Options: 'nearest', 'index', 'average', 'interpolate'
                    },
                },
                animation: {
                    duration: 1000, // Animation duration in milliseconds
                    easing: 'easeInOutCubic', // Easing function for animation
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        display: false
                    },
                    x: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Items',
                        },
                    },
                },
            },
        });
        // Store the new chart instance in a ref for later destruction
        chartInstanceRef.current = newChartInstance;

    }
  return (
      <>
                    <div className="fastanswerspage2">
                      <div className="purpleback">
                        <Grid container spacing={0}>
                          <Grid item xs={2}>
                            <div className="lpollheadclock2">
                              <CountdownCircleTimer
                                  isPlaying={true}
                                  duration={counter}
                                  size={50}
                                  colors={["#bca0dc", "#bca0dc", "#bca0dc", "#bca0dc"]}
                                  colorsTime={[10, 6, 3, 0]}
                                  onComplete={getdatas}
                              >
                                {renderTime}
                              </CountdownCircleTimer>
                            </div>
                    </Grid>
                       <Grid item xs={10}>
                        <div className="fastanswes">
                          <h2>{quest}</h2>
                        </div>
                          </Grid>
                        </Grid>
                      </div>
                      <br/>

<div style={{
  display: 'flex',
  flexWrap: "wrap",
  width: "100%",
  margin: "0 auto",
  padding:"10px",
  alignItems: "center",
  backgroundColor:"#f0f0f0",
  justifyContent: "center" }}>
                                  {remainingViews}
</div>
                                <br/>
                                <br/>
                                    <ol style={{textAlign:"left", margin:"10px", width:"98%"}}>
                                      {answerViews}
                                    </ol>
                                  <br/>
                                  {isopen ?
                                  <Grid container spacing={0}>
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={5} className="leftside">
                                      {isshowreset ?
                                              <Button onClick={handleReset} variant="danger">Reset</Button>
                                          : ""
                                      }
                                    </Grid>
                                    <Grid item xs={5} className="rightside">
                                      <Button onClick={handleSubmit} className="purplebutton">Submit</Button>
                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>
                                  </Grid>
                                      :
                                      <p className="redthis">{messages}</p>
                                   }
          <div>
              <canvas ref={chartRef} width={400} height={200}></canvas>
          </div>
                    </div>
          <br/>
          <br/>
          <br/>
    </>
  )
}
